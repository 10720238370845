import React, { Component } from 'react'
import {useParams}  from 'react-router-dom'
import functions from './functions'

import Header from 'components/Header/Header'
import LeftSidebar from 'components/LeftSidebar/LeftSidebar'
import Content from 'components/Content/Content'
import RightSidebar from 'components/RightSidebar/RightSidebar'
import helperSpaceDocumentationInterface from 'data/helperSpaceDocumentation/helperSpaceDocumentationInterface'

interface state{
    helperSpaceDoc : helperSpaceDocumentationInterface,
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
  

class Preview extends Component<any, state> {
    constructor(props) {
        super(props);
        
        this.init = this.init.bind(this);
        this.onPageClick = this.onPageClick.bind(this);

        this.state = {
            helperSpaceDoc : {} as helperSpaceDocumentationInterface,
        }

    }

    async componentWillMount() {
        await this.init();
    }

    async init() {
        const previewHandler = new functions();
        const {spaceId} = this.props.params;
        
        const result = await previewHandler.previewPagebySpaceId(spaceId);
        const finalResult = await previewHandler.previewContentbyPageId(result);
        await this.setState({ helperSpaceDoc: finalResult });
        
    }


    async onPageClick(e, page){
        e.preventDefault();
        await this.setState((prevState) => ({
            helperSpaceDoc: {
                ...prevState.helperSpaceDoc,
                currentPage : page,
            }
        }))
        const previewHandler = new functions();
        const result = await previewHandler.previewContentbyPageId(this.state.helperSpaceDoc);
        
        await this.setState({helperSpaceDoc: result});
    }


  render() {
    return (
        <>
            <Header 
                isSearchBarVisible={true}
                isSignInVisible={false}
                isHamburgerVisible={true}
            />
            <div className="docu-body" style={{display : "flex"}} >
                <LeftSidebar 
                    sidebarList={this.state.helperSpaceDoc}
                    onPageClick={this.onPageClick}
                />
                <Content 
                    currentPage = {
                        this.state.helperSpaceDoc.currentPage
                    }
                    prevBtn={this.onPageClick}
                    nextBtn={this.onPageClick}
                />
                <RightSidebar
                    spaceDoc={this.state.helperSpaceDoc}
                />
            </div>   
        </>
    )
  }
}

export default withParams(Preview);