import React, { Component } from 'react'
import './PricingCard.css'

interface props{
    pricingPlan : any,
    handleSubscription: any,
}
export default class PricingCard extends Component<props, any> {
  render() {
    return (
      <>
        <div className="buildfast-pricing-panel">
            <div className="buildfast-pricing-container">

                <div className="buildfast-pricing-heading">
                    <p>Fair pricing for your whole company.</p>
                </div>


                <div className="buildfast-pricing-card-container">
                    <div className="buildfast-pricing-all-card">
                        
                        <div className="buildfast-pricing-card card1">
                            
                            <div className="buildfast-card-top">
                                <div className="buildfast-feature-header">
                                    <div className="buildfast-feature-header-element header-element1">
                                        <p>TEST</p>
                                    </div>
                                </div>
                                <div className="buildfast-card-top-img">
                                    <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/Intersect_cir2.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860575"
                                        alt="img"/>
                                </div>
                            </div>

                            <div className="buildfast-card-middle">
                                <p>Basic</p>
                                <p>For upto 10 users</p>
                                <p>Perfect to test officely for small teams</p>
                                <div onClick={(e)=>this.props.handleSubscription(e, this.props?.pricingPlan?.monthly?.basic?.id)} className="buildfast-pricing-btn">Subscribe in ${this.props?.pricingPlan?.monthly?.basic.amount}/month only</div>
                            </div>

                            <div className="buildfast-card-bottom">
                                <div className="buildfast-card-bottom-heading">
                                    <p>Includes:</p>
                                </div>
                                <div className="buildfast-card-info-row">
                                    <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/check.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860056" alt=""/>
                                    <p>Table, list, task and kanban board</p>
                                </div>
                                <div className="buildfast-card-info-row">
                                    <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/check.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860056" alt=""/>
                                    <p>Slack integration</p>
                                </div>
                                <div className="buildfast-card-info-row">
                                    <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/check.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860056" alt=""/>
                                    <p>Sync status</p>
                                </div>
                                <div className="buildfast-card-info-row">
                                    <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/check.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860056" alt=""/>
                                    <p>Customisable healthy and safety setting</p>
                                </div>
                            </div>

                        </div>

                        <div className="buildfast-pricing-card card2">
                            
                            <div className="buildfast-card-top">
                                <div className="buildfast-feature-header">
                                    <div className="buildfast-feature-header-element header-element2">
                                        <p>SCALE</p>
                                    </div>
                                </div>
                                <div className="buildfast-card-top-img">
                                    <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/Intersect_rect.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860353"
                                        alt=""/>
                                </div>
                            </div>

                            <div className="buildfast-card-middle">
                                <p id="middle-price">Pro</p>
                                <p>For upto 10 users</p>
                                <p>Perfect to test officely for small teams</p>
                                <div onClick={(e)=>this.props.handleSubscription(e, this.props?.pricingPlan?.monthly?.pro?.id)} className="buildfast-pricing-btn">Subscribe in ${this.props?.pricingPlan?.monthly?.pro?.amount}/month only</div>
                            </div>

                            <div className="buildfast-card-bottom">
                                <div className="buildfast-card-bottom-heading">
                                    <p>Everything in Test, plus:</p>
                                </div>
                                <div className="buildfast-card-info-row">
                                    <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/check.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860056" alt=""/>
                                    <p>Unlimited number of employees</p>
                                </div>
                                <div className="buildfast-card-info-row">
                                    <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/check.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860056" alt=""/>
                                    <p>Google calender</p>
                                </div>
                                <div className="buildfast-card-info-row">
                                    <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/check.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860056" alt=""/>
                                    <p>Integration office</p>
                                </div>
                                <div className="buildfast-card-info-row">
                                    <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/check.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860056" alt=""/>
                                    <p>Automatic scheduling</p>
                                </div>
                            </div>
                            <div className="buildfast-bottom-text">
                                <p>*We only charge for the employees that officely to book into the office.</p>
                            </div>

                        </div>

                        <div className="buildfast-pricing-card card3">
                            
                            <div className="buildfast-card-top">
                                <div className="buildfast-feature-header">
                                    <div className="buildfast-feature-header-element">
                                        <p>ENTERPRISES</p>
                                    </div>
                                </div>
                                <div className="buildfast-card-top-img">
                                    <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/Intersect.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860525"
                                        alt=""/>
                                </div>
                            </div>

                            <div className="buildfast-card-middle">
                                {/* <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/message.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860199"
                                    alt=""/> */}
                                <p >Premium</p>
                                <p>For upto 10 users</p>
                                <p>Perfect to test officely for small teams</p>
                                <div onClick={(e)=>this.props.handleSubscription(e, this.props?.pricingPlan?.monthly?.premium?.id)} className="buildfast-pricing-btn">Subscribe in ${this.props?.pricingPlan?.monthly?.premium?.amount}/month only</div>
                            </div>

                            <div className="buildfast-card-bottom">
                                <div className="buildfast-card-bottom-heading">
                                    <p>Everything in Scale, plus:</p>
                                </div>
                                <div className="buildfast-card-info-row">
                                    <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/check.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860056" alt=""/>
                                    <p>Volume discounting pricing</p>
                                </div>
                                <div className="buildfast-card-info-row">
                                    <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/check.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860056" alt=""/>
                                    <p>1:1 onboarding</p>
                                </div>
                                <div className="buildfast-card-info-row">
                                    <img src="https://ik.imagekit.io/c8xfvkbcd/designs/Pricing/Pricing1/check.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677480860056" alt=""/>
                                    <p>Dedicated status manager</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>
      </>
    )
  }
}
