import React, { Component } from 'react'
import InputListItem from '../InputListItem/InputListItem'
import './InputSidebar.css'
import $ from 'jquery';

import OrgImg from 'assets/svg/organization.svg'
import OrgProfile from 'assets/svg/org_profile.svg'
import OrgNoti from 'assets/svg/org_noti.svg'
import OrgPlus from 'assets/svg/org_plus.svg'
import OrgSetting from 'assets/svg/org_setting.svg'
import env from 'config'

import userFormInterface from 'data/user/userFormInterface';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';


interface props{
    createSpace: any,
    inputChanged : any,
    onBlur : any,
    user: userFormInterface,
    handleCurrentSpaceId: any
    selectedSpaceId: string
}
interface state{
    spaceName: string,
}
export default class InputSidebar extends Component<props, state> {
    constructor(props){
        super(props);

        this.openSetting = this.openSetting.bind(this);
        this.openNotification = this.openNotification.bind(this);
        this.openDialogBox = this.openDialogBox.bind(this);
        this.hideDialogBox = this.hideDialogBox.bind(this);
        this.sendSpaceValue = this.sendSpaceValue.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    openMobSidebar(){
        $(".ip-section").toggle(500);
    }
    openNotification(){
        $(".noti-panel").toggleClass("noti-panel-active");
    }
    openSetting(){
        $(".setting-menu").toggleClass("setting-menu-active");
    }
    openDialogBox(){
        $('.overlay-space-container').show();
    }
    hideDialogBox(){
        $('.overlay-space-container').hide();
    }
    sendSpaceValue(){
        let spaceName = $('input[name = space-name]').val();
        let isPublic = $('input[name = space-checkbox]').is(':checked');
        if(spaceName.length > 0){
            this.props.createSpace(spaceName, isPublic);
            this.hideDialogBox();
        }
        else{
            alert("Space Name can't be blank");
        }
        
    }

    handleLogout() {
        localStorage.removeItem(env.app.appName +'_token')
        window.location.href = '/'
    }

  render() {
    return (
      <>
        <section className="ip-section">
            <div className="overlay-space-container">
                <div className="overlay-wrapper">
                    <div className="overlay-box">
                        <div>
                            <div className="close-btn">
                                <div className="close-btn-icon" onClick={this.hideDialogBox}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 36 36"><path fill="#3b454e" d="m19.41 18l8.29-8.29a1 1 0 0 0-1.41-1.41L18 16.59l-8.29-8.3a1 1 0 0 0-1.42 1.42l8.3 8.29l-8.3 8.29A1 1 0 1 0 9.7 27.7l8.3-8.29l8.29 8.29a1 1 0 0 0 1.41-1.41Z"/><path fill="none" d="M0 0h36v36H0z"/></svg>
                                </div>
                            </div>
                            <p className='space-text'>Enter the Space Name</p>
                            <input type="text" name="space-name" id="" className='space-input'/>
                            <div className="space-public">
                                <input className='custom-checkbox' type="checkbox" name="space-checkbox" id="" />
                                <p>Public</p>
                            </div>
                        </div>
                        <div>
                            <div className='space-btn-box'>
                                <div className="save-space" onClick={this.sendSpaceValue}>
                                    <p>Save</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ip-container">
                <div className="ip-container-top">
                    <div className="header-search">
                        <input type="text" name="" id="" placeholder='Search' />
                        <div className="header-search-sc">
                            <p>⌘K</p>
                        </div>
                    </div>
                    <div className="line"></div>

                    <div className="ip-organize">
                        <div className='ip-organize-left'>
                            <div className="ip-organize-img">
                            <img src={OrgProfile} alt="org-profile" />
                            </div>
                            <div className="ip-organize-name">
                                {
                                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.user) && !stringUtilsImpl.isUndefinedEmptyorNull(this.props.user.organizations) && this.props.user.organizations.length > 0 ?
                                    <p contentEditable spellCheck='false' onBlur={(e) => this.props.onBlur(e, "organizationName", this.props.user.organizations[0].Id)} onInput={this.props.inputChanged}>
                                        {this.props.user.organizations[0].organizationName}
                                    </p>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        <div>
                            <div className="ip-organize-dd">
                                <img src={OrgImg} alt="org-img" />
                            </div>
                        </div>
                    </div>
                    {
                        !stringUtilsImpl.isUndefinedEmptyorNull(this.props.user) && !stringUtilsImpl.isUndefinedEmptyorNull(this.props.user.organizations) && this.props.user.organizations.length > 0 && !stringUtilsImpl.isUndefinedEmptyorNull(this.props.user.organizations[0].spaces) && this.props.user.organizations[0].spaces.length > 0 ?
                         
                        this.props.user.organizations[0].spaces.map((space) => {
                            return(
                                space.isShared !== true ?
                                <InputListItem 
                                    space = {space}
                                    handleCurrentSpaceId = {this.props.handleCurrentSpaceId}
                                    selectedSpaceId={this.props.selectedSpaceId}
                                />
                                :
                                <></>
                            )
                        })
                        :
                        <></>
                    }
                    <div className="add-list" onClick={this.openDialogBox}>
                        <p>+</p>
                        <p>New space</p>
                    </div>
                    <div className="line"></div>
                    <div className="shared-space">
                        <div className="shared-space-heading">Shared Spaces</div>
                        <div className="shared-space-items">
                        {
                        !stringUtilsImpl.isUndefinedEmptyorNull(this.props.user) && !stringUtilsImpl.isUndefinedEmptyorNull(this.props.user.organizations) && this.props.user.organizations.length > 0 && !stringUtilsImpl.isUndefinedEmptyorNull(this.props.user.organizations[0].spaces) && this.props.user.organizations[0].spaces.length > 0 ?
                         
                        this.props.user.organizations[0].spaces.map((space) => {
                            return(
                                space.isShared === true ?
                                <InputListItem 
                                    space = {space}
                                    handleCurrentSpaceId = {this.props.handleCurrentSpaceId}
                                    selectedSpaceId={this.props.selectedSpaceId}
                                />
                                :
                                <></>
                            )
                        })
                        :
                        <></>
                    }
                        </div>
                    </div>
                </div>
                <div className="ip-container-bottom">
                    <div className="line"></div>
                    <div className="ip-button">
                        <div className="ip-button-left" onClick={this.openNotification}>
                        <img src={OrgNoti} alt="" />
                        </div>
                        <div className="ip-button-mid" onClick={this.openDialogBox} >
                            <img src={OrgPlus} alt="" />
                        </div>
                        <div className="ip-button-right " onClick={this.openSetting}>
                        <img src={OrgSetting} alt="" />
                        </div>

                        <div className="setting-menu "  onClick={this.handleLogout}>
                            <p><span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="none" stroke="#5b6975" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 21a9 9 0 0 0 8.997-9.252a7 7 0 0 1-10.371-8.643A9 9 0 0 0 12 21Z"/></svg></span> Dark Mode</p>
                            <p><span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="#5b6975" d="M4 12a.5.5 0 0 0 .5.5h8.793l-2.647 2.646a.5.5 0 1 0 .707.708l3.5-3.5a.5.5 0 0 0 0-.707l-3.5-3.5a.5.5 0 0 0-.707.707l2.647 2.646H4.5a.5.5 0 0 0-.5.5zM17.5 2h-11A2.502 2.502 0 0 0 4 4.5v4a.5.5 0 0 0 1 0v-4A1.5 1.5 0 0 1 6.5 3h11A1.5 1.5 0 0 1 19 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 5 19.5v-4a.5.5 0 0 0-1 0v4A2.502 2.502 0 0 0 6.5 22h11a2.502 2.502 0 0 0 2.5-2.5v-15A2.502 2.502 0 0 0 17.5 2z"/></svg></span> Sign Out </p>
                        </div>

                        <div className="noti-panel">
                            <div className="noti-header">
                                <p>Notification</p>
                                <p className='mark-read'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16"><path fill="none" stroke="#5b6975" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m1.75 9.75l2.5 2.5m3.5-4l2.5-2.5m-4.5 4l2.5 2.5l6-6.5"/></svg></p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
        <section className="ip-section-mob">
            <div className="ip-container-mob">
            <div className="ip-sidebar-icon" onClick={this.openMobSidebar}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 48 48"><path fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M31 36L19 24l12-12"/></svg>
            </div>
            <div className="ip-organize-name">
                {
                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.user) && !stringUtilsImpl.isUndefinedEmptyorNull(this.props.user.organizations) && this.props.user.organizations.length > 0 ?
                    <p contentEditable spellCheck='false' onBlur={(e) => this.props.onBlur(e, "organizationName", this.props.user.organizations[0].Id)} onInput={this.props.inputChanged}>
                        {this.props.user.organizations[0].organizationName}
                    </p>
                    :
                    <></>
                }
            </div>
            </div>
        </section>
      </>
    )
  }
}
