import { IKCore } from "imagekitio-react"
import env from 'config'
import functions from "./functions";

export default class Imagekit {

    currentContext: any
    publicKey: string;
    urlEndpoint: string;

    initializeIKCoreContext() {
        this.currentContext = new IKCore({
            publicKey: env.imageKit.publicKey,
            urlEndpoint: env.imageKit.urlEndpoint,
        });
    }

    getcurrentContext() {
        return this.currentContext;
    }

    getAuthorizedToken() {
        const imageKitHandler = new functions();
        let token = imageKitHandler.getAuthorizationToken();
        return token;
    }

    async uploadImagethroughIKCoreContext(selectedFile) {

        const authToken = await this.getAuthorizedToken();
        let fileUploadData = {
            file: selectedFile,
            fileName: selectedFile.name,
            token: authToken.data.token,
            expire: authToken.data.expire,
            signature: authToken.data.signature
        }   

        let image = await this.currentContext.upload(fileUploadData);
        return image;
    }

}