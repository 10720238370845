import React, { Component, RefObject } from 'react'
import Select, {StylesConfig} from 'react-select'

import $ from 'jquery'
import './ShareOverlay.css'
import spaceInterface from 'data/space/spaceInterface'




const options = [
    { value: 'ADMINISTRATOR', label: 'ADMINISTRATOR' },
    { value: 'EDITOR', label: 'EDITOR' },
    { value: 'VIEWER', label: 'VIEWER' }
]

const selectStyles: StylesConfig = {
    control: (styles) => ({...styles, fontSize: ".9rem",
    border: "1px solid var(--accent4)",
    padding: "0px 10px",
    borderRadius: ".4rem",
    cursor: "pointer" }),
    input: (styles) => ({ ...styles, position: 'absolute', zIndex: '29' }),
    placeholder: (styles) => ({ ...styles, zIndex: '29' }),
    singleValue: (styles, { data }) => ({ ...styles,  }),
};


interface props{
    adminUser: any,
    searchUser: any,
    userSearchList: any,
    navItemRefactored: any,
    sendInviteLink : any,
    spacePermissionList: spaceInterface,
    handleSelectedRole: any,
    handleSubDomain: any,
    showError: string,
}
interface state{
    selectedInputValue: string,
    selectedEmailUserId: string,
    onClickInviteInput: boolean,
    selectedRoleValue: string,
    selectedTab: string,
    subDomainValue: string,
}
export default class ShareOverlay extends Component<props, state> {
    private inputRef: RefObject<HTMLInputElement> = React.createRef();

    constructor(props){
        super(props);

        this.handleOverlayClose  = this.handleOverlayClose.bind(this);
        this.copyInviteUrl = this.copyInviteUrl.bind(this);
        this.handleSelectedItem = this.handleSelectedItem.bind(this);
        this.handleSelectedRoleValue = this.handleSelectedRoleValue.bind(this);
        this.handleSubDomainInput = this.handleSubDomainInput.bind(this);

        this.state = {
            selectedInputValue: '',
            selectedEmailUserId: '',
            onClickInviteInput: false,
            selectedRoleValue: 'VIEWER',
            selectedTab: 'tab1',
            subDomainValue: '',
        }
    }

    componentDidMount(): void {
        this.init();
    }
    async init() {

    }
    
    handleSelectedItem(e, value, shouldClose = false, userID){
        this.setState({selectedInputValue: value, selectedEmailUserId: userID});
        if(shouldClose) {
            this.setState({onClickInviteInput: false});
        }
    }

    handleOverlayClose(){
        this.setState({selectedTab: 'tab1'});
        $('.share-ol-container').hide();
    }
    handleSelectedRoleValue(role, permissionId){
        this.props.handleSelectedRole(role.value, permissionId);
        for(let i=0; i < this.props.spacePermissionList.manageUsers.length; i++){
            if(this.props.spacePermissionList.manageUsers[i].Id === permissionId){
                this.props.spacePermissionList.manageUsers[i].accessType = role.value;
            }
        }
        console.log(role.value, permissionId);
    }

    copyInviteUrl(){
        const inputElement = this.inputRef.current as HTMLInputElement;
        inputElement.select();
        navigator.clipboard.writeText(inputElement.value)
        .then(() => {
          inputElement.setSelectionRange(0, inputElement.maxLength);
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });

    }

    handleSelectedTab(e, currentTab){
        if(currentTab === 'tab1'){
            this.setState({selectedTab: 'tab1'})
        }
        else if(currentTab === 'tab2'){
            this.setState({selectedTab: 'tab2'})
        }
        else if(currentTab === 'tab3'){
            this.setState({selectedTab: 'tab3'})
        }
    }

    handleSubDomainInput(e){
        this.setState({subDomainValue: e.target.value});
    }


  render() {
    return (
      <>
        <div className={"share-ol-container "}>
            <div className="share-ol-wrapper">
                <div className="share-ol-box">
                 <div className="share-ol-left">
                    <div className="share-ol-list">
                        <div className={this.state.selectedTab === 'tab1' ? "share-ol-listitem listitem-active" :"share-ol-listitem" } onClick={(e) => this.handleSelectedTab(e, 'tab1')}>
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512"><g transform="translate(512 0) scale(-1 1)"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72Z"/><path fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" d="M336 304c-65.17 0-127.84 32.37-143.54 95.41c-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304Z"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94Z"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M206 306c-18.05-8.27-37.93-11.45-59-11.45c-52 0-102.1 25.85-114.65 76.2c-1.65 6.66 2.53 13.25 9.37 13.25H154"/></g></svg></span>
                            <span>Invite Members</span>
                        </div>
                        <div className={this.state.selectedTab === 'tab2' ? "share-ol-listitem listitem-active" :"share-ol-listitem" } onClick={(e) => this.handleSelectedTab(e, 'tab2')}>
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path fill="currentColor" d="M128 26a102 102 0 1 0 102 102A102.12 102.12 0 0 0 128 26Zm81.57 64h-40.18a139.15 139.15 0 0 0-23.45-50.2A90.32 90.32 0 0 1 209.57 90Zm8.43 38a89.7 89.7 0 0 1-3.83 26h-42.31a159 159 0 0 0 0-52h42.31a89.7 89.7 0 0 1 3.83 26Zm-90 90a1.75 1.75 0 0 1-1.32-.59C113.8 203.54 104.34 185.73 99 166h58c-5.34 19.73-14.8 37.54-27.68 51.41a1.75 1.75 0 0 1-1.32.59Zm-31.69-64a147.48 147.48 0 0 1 0-52h63.38a147.48 147.48 0 0 1 0 52ZM38 128a89.7 89.7 0 0 1 3.83-26h42.31a159 159 0 0 0 0 52H41.83A89.7 89.7 0 0 1 38 128Zm90-90a1.75 1.75 0 0 1 1.32.59C142.2 52.46 151.66 70.27 157 90H99c5.34-19.73 14.8-37.54 27.68-51.41A1.75 1.75 0 0 1 128 38Zm-17.94 1.8A139.15 139.15 0 0 0 86.61 90H46.43a90.32 90.32 0 0 1 63.63-50.2ZM46.43 166h40.18a139.15 139.15 0 0 0 23.45 50.2A90.32 90.32 0 0 1 46.43 166Zm99.51 50.2a139.15 139.15 0 0 0 23.45-50.2h40.18a90.32 90.32 0 0 1-63.63 50.2Z"/></svg></span>
                            <span>Publish to Web</span>
                        </div>
                        <div className={this.state.selectedTab === 'tab3' ? "share-ol-listitem listitem-active" :"share-ol-listitem" } onClick={(e) => this.handleSelectedTab(e, 'tab3')}>
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 256 256"><path fill="currentColor" d="M222 152a6 6 0 0 1-6 6h-26v20h18a6 6 0 0 1 0 12h-18v18a6 6 0 0 1-12 0v-56a6 6 0 0 1 6-6h32a6 6 0 0 1 6 6ZM90 172a26 26 0 0 1-26 26H54v10a6 6 0 0 1-12 0v-56a6 6 0 0 1 6-6h16a26 26 0 0 1 26 26Zm-12 0a14 14 0 0 0-14-14H54v28h10a14 14 0 0 0 14-14Zm84 8a34 34 0 0 1-34 34h-16a6 6 0 0 1-6-6v-56a6 6 0 0 1 6-6h16a34 34 0 0 1 34 34Zm-12 0a22 22 0 0 0-22-22h-10v44h10a22 22 0 0 0 22-22ZM42 112V40a14 14 0 0 1 14-14h96a6 6 0 0 1 4.25 1.76l56 56A6 6 0 0 1 214 88v24a6 6 0 0 1-12 0V94h-50a6 6 0 0 1-6-6V38H56a2 2 0 0 0-2 2v72a6 6 0 0 1-12 0Zm116-30h35.52L158 46.48Z"/></svg></span>
                            <span>Export as PDF</span>
                        </div>
                    </div>
                 </div>
                 <div className="share-ol-right">
                    <div className="share-ol-close">
                        <svg onClick={this.handleOverlayClose} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M20 20L4 4m16 0L4 20"/></svg>
                    </div>
                    <div className="share-ol-heading">
                        {
                            {
                                'tab1': <>Invite Member</>,
                                'tab2': <>Publish to Web</>,
                                'tab3': <>Export as PDF</>
                            }[this.state.selectedTab]
                        }
                    </div>
                    <div className="share-ol-subheading">
                        {
                            {
                                'tab1': <>Invite people to your content and start collaborating straight away.</>,
                                'tab2': <>Publish this space to web with subdomain.</>,
                                'tab3': <>Export the content of space into PDF file.</>
                            }[this.state.selectedTab]
                        }
                        
                    </div>
                    
                    <div>
                    {
                            {
                                'tab1': <>
                                            <div className="im-content">
                                                <div className="im-share-input">
                                                    <input onClick={() => this.setState({onClickInviteInput: true})} onChange={(e) => this.props.searchUser(e, this.handleSelectedItem)} value={this.state.selectedInputValue} placeholder='Add someone, a team, or an eṃail' className='invite-input' type='search' name="" id="" />
                                                        
                                                    <div className="invite-btn" onClick={()=> this.props.sendInviteLink('VIEWER', this.state.selectedEmailUserId)}>
                                                        Invite
                                                    </div>
                                                    <div className={this.state.onClickInviteInput ? "user-list user-list-active" : "user-list"}>
                                                        {
                                                            this.props.userSearchList.length > 0 ?
                                                            <>
                                                            {
                                                                this.props.userSearchList.map(userItem =>{
                                                                    return(
                                                                        <div className="user-item" onClick={(e) => this.handleSelectedItem(e, userItem.email, true, userItem.Id)}>
                                                                            <div>{userItem.email} <span className='user-item-name'>({userItem.firstname} {userItem.lastname})</span> </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            </>
                                                            :
                                                            <>
                                                                <div className='user-list-alert' ><span>No User Found... </span></div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="im-member-list">
                                                    <div>
                                                        <div>Members with access</div>
                                                        <div></div>
                                                    </div>
                                                    <div>
                                                        <div className="im-listitem">
                                                            <div className="listitem-left">
                                                                <div className="im-image"><img src={this.props.adminUser?.image} alt="" /></div>
                                                                <div className='im-details'>
                                                                    <div className="im-name">{`${this.props.adminUser?.firstname} ${this.props.adminUser?.lastname}`}</div>
                                                                    <div className="im-email">
                                                                        {this.props.adminUser?.email}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="listitem-right">
                                                                <Select isDisabled={true} styles={selectStyles} options={options} defaultValue={[{value: `ADMIN`, label: 'ADMINISTRATOR'}]} />
                                                            </div>
                                                        </div>
                                                        {
                                                            this.props.spacePermissionList.manageUsers?.length > 0 ?
                                                            <>
                                                                {
                                                                    this.props.spacePermissionList.manageUsers.map((userDetail) => {
                                                                        return(
                                                                            <div className="im-listitem">
                                                                                <div className="listitem-left">
                                                                                    <div className="im-image"><img src={userDetail.user.image} alt="" /></div>
                                                                                    <div className='im-details'>
                                                                                        <div className="im-name">{`${userDetail.user?.firstname} ${userDetail.user?.lastname}`}</div>
                                                                                        <div className="im-email">
                                                                                            {`${userDetail.user?.email}`}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="listitem-right">
                                                                                <Select onChange={(role)=>this.handleSelectedRoleValue(role, userDetail.Id)} styles={selectStyles} options={options} defaultValue={[{value: `${userDetail.accessType}`, label: 'VIEWER'}]} />
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                        }
                                                        
                                                    </div>
                                                </div>
                        
                                                <div className="im-member-list">
                                                    <div>
                                                        <div>Invite Links</div>
                                                        <div></div>
                                                    </div>
                                                    <div>
                                                        
                                                        <div className="invite-link">
                                                            <input value={`http://localhost:3000/preview/${this.props.navItemRefactored.currentSpaceId}`} ref={this.inputRef} type="text" />
                                                            <button onClick={this.copyInviteUrl}>Copy</button>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </>,
                                'tab2': <>
                                            <div className="pw-content">
                                                <div className="pw-subdomain-box">
                                                    <label className='pw-subdomain-label' htmlFor="">Enter subdomain to publish</label>
                                                    <div className='pw-subdomain-input-box'>
                                                        <input type="text" className='invite-input' onChange={(e) => this.handleSubDomainInput(e)}/>
                                                        <div className='pw-subdomain-input'>.devbook.co.in</div>
                                                    </div>
                                                    <div className="pw-subdomain-err">{this.props.showError}</div>
                                                </div>
                                                <button className="pw-publish-btn" onClick={(e) => this.props.handleSubDomain(e, this.state.subDomainValue)}>Publish</button>
                                            </div>  
                                        </>,
                                'tab3': <></>
                            }[this.state.selectedTab]
                        }
                    </div>

                 </div>
                </div>
            </div>
        </div>
      </>
    )
  }
}
