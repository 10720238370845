import React, { Component } from 'react'
import '../SubSidebar/SubSidebar.css'
import $ from 'jquery';

import spaceDocumentationInterface from 'data/spaceDocumentation/spaceDocumentationInterface';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';

interface props{
  page: spaceDocumentationInterface;
  renamePage: any
  openDialogBoxForPage: any
  handleCurrentPage: any
  selectedPage: any
}
interface state{
  openPageMenu: boolean,
}

export default class NewPage extends Component<props, state> {
  constructor(props){
    super(props);

    this.openPageMenu = this.openPageMenu.bind(this);
    
    this.state = {
      openPageMenu : false,
    }
  }

  openPageMenu(e){
    e.preventDefault();
    $(e.currentTarget).addClass('page-menu-active');
    this.setState((prevState) => ({
      openPageMenu: !prevState.openPageMenu
    }))
  
  }
  
  render() {
    return (
      <>
        <div className={(!stringUtilsImpl.isUndefinedEmptyorNull(this.props.selectedPage) && (this.props.selectedPage.Id === this.props.page.Id)) ? "page-heading page-heading-active" : "page-heading"} onClick={(e)=>{this.props.handleCurrentPage(e, this.props.page, this.props.page.isCreate)}}>
          {
            !stringUtilsImpl.isUndefinedEmptyorNull(this.props.page) && !stringUtilsImpl.isUndefinedEmptyorNull(this.props.page.pageName) ?
            <p>{this.props.page.pageName}</p>
            :
            <></>
          }
            
            <div className='page-content' onClick={this.openPageMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
                <path
                fill="black"
                d="M9 15.25a1.25 1.25 0 1 1 2.5 0a1.25 1.25 0 0 1-2.5 0Zm0-5a1.25 1.25 0 1 1 2.5 0a1.25 1.25 0 0 1-2.5 0Zm0-5a1.249 1.249 0 1 1 2.5 0a1.25 1.25 0 1 1-2.5 0Z"
                />
            </svg>
            </div>
            <div className={+ this.state.openPageMenu ? "page-menu page-menu-active" : "page-menu "} >
              <p onClick={(e) => this.props.openDialogBoxForPage(e, this.props.page.groupName, this.props.page.Id)} ><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="none" stroke="#3b454e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m5 16l-1 4l4-1L19.586 7.414a2 2 0 0 0 0-2.828l-.172-.172a2 2 0 0 0-2.828 0L5 16ZM15 6l3 3m-5 11h8"/></svg></span>Rename</p>
              <p><span><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="#3b454e" d="M18 19a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V7H4V4h4.5l1-1h4l1 1H19v3h-1v12M6 7v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V7H6m12-1V5h-4l-1-1h-3L9 5H5v1h13M8 9h1v10H8V9m6 0h1v10h-1V9Z"/></svg></span> Delete </p>
          </div>
        </div>
      </>
    )
  }
}
