import React, { Component } from 'react'
import { Navigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface';
import functions from './functions';

import env from 'config.js'
import '../../App.css'
import Header from 'components/Header/Header';

export default class Login extends Component<any, { pageSEO: pageSEOInterface }> {
    constructor(props){
        super(props);

        this.init = this.init.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onFailure = this.onFailure.bind(this);

        this.state = {
            pageSEO: {} as pageSEOInterface,
        }
    }

    componentDidMount(): void {
      this.init();
    }

    async init() {
      const LoginHandler = new functions();
      const SEO = LoginHandler.addSEO();
      this.setState({ pageSEO: SEO });
    }

    onSuccess(res) {
        this.loginUser(res);
    }

    async loginUser(res) {
        const LoginHandler = new functions();;
        const user = await LoginHandler.loginUser(res);
        if(user.success) {
            localStorage.setItem(env.app.appName +'_token', user.user);
            window.location.reload();
        }
    }

    onFailure(res) {
        console.log('[Login failed] res:', res);
    }

  render() {

    if (localStorage.getItem(env.app.appName +'_token')) {
      return <Navigate to='/dashboard' />
    }

    return (
      <>
        <Header
          isSearchBarVisible={false}
          isSignInVisible={true}
          isHamburgerVisible={false}
        />
        <section className="both">
        <div className="left-login-section">
            <div className="left-content">
            </div>
            
        </div>


        <div className="right">
            <div className="right-content">
                <div className='right-header'>Create document like never before</div>
                <div><p className='right-subheader'>Increase your team efficieny with our webapp, making complex concepts accessible to all through clear and concise documentation</p></div>
                <div className="right-heading">
                    <p>Login to DevBook</p>
                </div>
                <div className="right-para">
                    <p>Welcome back! Login with Goolge Account in just one click</p>
                </div>
                <div className="right-google">
                    <GoogleOAuthProvider clientId={env.googleLogin.clientid}>
                      <GoogleLogin
                        onSuccess={this.onSuccess}
                        onError={() => this.onFailure}
                      />  
                  </GoogleOAuthProvider>
                </div>
              
            </div>
        </div>
    </section>
      </>
    )
  }
}
