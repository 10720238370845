import React, { Component } from 'react'
import $ from 'jquery';

import InputSidebar from 'components/InputSidebar/InputSidebar'
import InputTopbar from 'components/InputTopbar/InputTopbar'
import SubSidebar from 'components/SubSidebar/SubSidebar'
import InputArea from 'components/InputArea/InputArea'


import pageSEOInterface from 'data/SEO/pageSEO/pageSEOInterface'
import userFormInterface from 'data/user/userFormInterface'
import spaceDocumentationInterface from 'data/spaceDocumentation/spaceDocumentationInterface'

import functions from './functions'
import helperSpaceDocumentationInterface from 'data/helperSpaceDocumentation/helperSpaceDocumentationInterface'
import spaceInterface from 'data/space/spaceInterface';
import { newGroupJson, newPageJson } from './context/newDocumentJson'
import Overlay from 'components/Overlay/Overlay'
import Reloader from 'components/Reloader/Reloader';
import SavedOverlay from 'components/SavedOverlay/SavedOverlay';
import ShareOverlay from 'components/ShareOverlay/ShareOverlay';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';

interface state {
    pageSEO: pageSEOInterface,
    user: userFormInterface,
    userSearched: userFormInterface[],
    navItemRefactored: helperSpaceDocumentationInterface,
    spaceDocumentation: spaceDocumentationInterface[],
    isSubsidebarOpen: boolean,
    showReloader: boolean
    currentContent: string,
    spacePermissionList : spaceInterface,
    showSubDomainError: string

}


export default class Dashboard extends Component<any, state> {

    constructor(props) {
        super(props);

        this.init = this.init.bind(this);
        this.createSpace = this.createSpace.bind(this);
        this.handleCurrentSpaceId = this.handleCurrentSpaceId.bind(this);
        this.handleCurrentPage = this.handleCurrentPage.bind(this);
        this.handleSpaceDocumentationChange = this.handleSpaceDocumentationChange.bind(this);

        this.setOrganizationName = this.setOrganizationName.bind(this);
        this.saveOrganizationNametoDB = this.saveOrganizationNametoDB.bind(this);
        this.savePagetoDB = this.savePagetoDB.bind(this);
        this.saveContent = this.saveContent.bind(this);
        this.addNewPage = this.addNewPage.bind(this);
        this.addNewGroup = this.addNewGroup.bind(this);
        this.addGroupPage = this.addGroupPage.bind(this);
        this.renameGroup = this.renameGroup.bind(this);
        this.renamePage = this.renamePage.bind(this);
        this.toggleSubsidebar = this.toggleSubsidebar.bind(this);
        this.updateEditorContent = this.updateEditorContent.bind(this);
        this.updateSubSidebar = this.updateSubSidebar.bind(this);
        this.searchUser = this.searchUser.bind(this);
        this.sendInviteLink = this.sendInviteLink.bind(this);
        this.handleSelectedRole = this.handleSelectedRole.bind(this);
        this.handlePublishDomain = this.handlePublishDomain.bind(this);

        this.openDialogBoxForGroup = this.openDialogBoxForGroup.bind(this);
        this.openDialogBoxForPage = this.openDialogBoxForPage.bind(this);
        this.hideDialogBox = this.hideDialogBox.bind(this);
        

        this.state = {
            pageSEO: {} as pageSEOInterface,
            user: {} as userFormInterface,
            userSearched: [] as userFormInterface[],
            spaceDocumentation: [] as spaceDocumentationInterface[],
            navItemRefactored: {} as helperSpaceDocumentationInterface,
            isSubsidebarOpen : false,
            showReloader : false,
            currentContent : "",
            spacePermissionList: {} as spaceInterface,
            showSubDomainError: ''
        }
    }

    componentDidMount(): void {
        this.init();
    }

    handleSpaceDocumentationChange(e, targetName) {
        e.preventDefault();
        const value = e.currentTarget.textContent;
        console.log(value);
    }

    async init() {
        const dashboardHandler = new functions();
        const SEO = dashboardHandler.addSEO();
        this.setState({ pageSEO: SEO });

        const userResult = await dashboardHandler.getAllDetails();
        await this.setState({ user: userResult });
        
    }
    
    async handleCurrentSpaceId(e, spaceId, spaceName) {
        e.preventDefault();
        if(this.state.navItemRefactored.currentSpaceId !== spaceId) {
            await this.setState((prevState) => ({
                navItemRefactored: {
                    ...prevState.navItemRefactored,
                    currentSpaceId: spaceId,
                    currentSpaceName: spaceName
                }
            }))
            this.updateSubSidebar(spaceId, spaceName);
            this.getAccessMembers(spaceId);
        }
    }

    async updateSubSidebar(spaceId, spaceName) {
        const dashboardHandler = new functions();
        const result = await dashboardHandler.updateSubSidebar(this.state.navItemRefactored, spaceId, spaceName);
        this.setState({
            navItemRefactored: result
        })
    }

    async handleCurrentPage(e, page, pageState){
        e.preventDefault();
        await this.setState((prevState) => ({
            navItemRefactored: {
                ...prevState.navItemRefactored,
                currentPage: page,
                currentPageIdState: pageState
            }
        }))

        this.updateEditorContent();
    }

    async updateEditorContent(){

        const dashboardHandler = new functions();
        const result = await dashboardHandler.updateEditorContent(this.state.navItemRefactored);
        if(result !== undefined) {
            await this.setState({navItemRefactored : result});
        }
    }

    setOrganizationName(e, targetName, organizationId){
        e.preventDefault();
        const value = e.currentTarget.textContent
        this.setState((prevState) => ({
            user: {
                ...prevState.user,
                organizations: prevState.user.organizations.map((org) => {
                    return {
                        ...org,
                        [targetName]: value,
                    };
                }),
            },
        }), () => {
            this.saveOrganizationNametoDB(organizationId);
        });
    }

    toggleSubsidebar(){
        this.setState((prevState) => ({
            isSubsidebarOpen: !prevState.isSubsidebarOpen,
        }));
    }

    
    async createSpace(spaceName:string, isPublic:boolean){

        const data = {
            spaceName: spaceName,
            isPublic: isPublic,
            organizationId: this.state.user.organizations[0].Id
        }

        const dashboardHandler = new functions();
        await dashboardHandler.createSpace(data);

        window.location.reload();
    }

    async savePagetoDB(){
        this.setState({showReloader: true});
        const dashboardHandler = new functions();
        const res = await dashboardHandler.savetoDB(this.state.navItemRefactored);
        if(res.data.success ===  true){
            this.setState({showReloader: false});
        }
    }

    async addNewPage(){

        const newPage = newPageJson;


        const dashboardHandler = new functions();
        const createPage = await dashboardHandler.addNewPage(newPage, this.state.navItemRefactored);
        this.setState({
            navItemRefactored: createPage
        })

    }

    async addNewGroup(){

        const newGroup = newGroupJson;

        const dashboardHandler = new functions();
        const navNew = await dashboardHandler.addNewGroup(newGroup, this.state.navItemRefactored);
        this.setState({
            navItemRefactored: navNew
        }) 
    }

    async addGroupPage(e,groupName){
        e.preventDefault();
        const newGroupPage = {...newPageJson, groupName: groupName};

        const dashboardHandler = new functions();
        const createGroupPage = await dashboardHandler.addGroupPage(newGroupPage, this.state.navItemRefactored);
        this.setState({
            navItemRefactored: createGroupPage
        })
    }

    async renameGroup(e, groupName) {
        e.preventDefault();
        const dashboardHandler = new functions();
        const navNew = await dashboardHandler.renameGroup(groupName, this.state.navItemRefactored);
        this.setState({
            navItemRefactored: navNew
        }) 

        this.hideDialogBox();
    }

    async renamePage(e, pageName){
        e.preventDefault();
        const dashboardHandler = new functions();
        const newValue = await dashboardHandler.renamePage(pageName, this.state.navItemRefactored);
        this.setState({
            navItemRefactored: newValue
        })

        this.hideDialogBox();
    }

    async saveOrganizationNametoDB(organizationId){
        const dashboardHandler = new functions();
        await dashboardHandler.saveOrganizationName(this.state.user.organizations[0], organizationId);
    }


    async saveContent(content){
        const dashboardHandler = new functions();
        const newContent = await dashboardHandler.saveContent(content, this.state.navItemRefactored);
        this.setState({
            navItemRefactored: newContent
        }) 
    }

    openDialogBoxForGroup(e, groupName) {
        e.preventDefault();
        this.setState((prevState) => ({
            navItemRefactored: {
                ...prevState.navItemRefactored,
                groupNameSelected: groupName
            }
        }))
        $('.rename-nav').show();
    }

    openDialogBoxForPage(e, groupName, pageId){
        e.preventDefault();
        this.setState((prevState) => ({
            navItemRefactored: {
                ...prevState.navItemRefactored,
                groupNameSelected: groupName,
                pageIdSelected: pageId
            }
        }))
        console.log("dialog: ", groupName, pageId )
        $('.rename-page').show();
    }

    hideDialogBox() {
        $('.rename-nav').hide();
        $('.rename-page').hide();
        $('.save-page').hide();
    }

    async searchUser(e, callbackFunction){
        let data = e.target.value;

        const dashboardHandler = new functions();
        const result = await dashboardHandler.searchUserForInvite(data)
        
        console.log(e.target.value, result);
        this.setState({userSearched : result})
        callbackFunction(e, data);
    }

    async sendInviteLink(accessType,   userId){
        let data = {
            accessType: accessType,
            spaceId: this.state.navItemRefactored.currentSpaceId,
            userId: userId
        }
        const dashboardHandler = new functions();
        const addedSpacePermission = await dashboardHandler.saveInviteLink(data)
        if(addedSpacePermission.success !== true){
            return alert('Already a member !')
        }
        await this.setState((prevState) => ({
            spacePermissionList: {
                ...prevState.spacePermissionList,
                manageUsers: [
                  ...prevState.spacePermissionList.manageUsers,
                  addedSpacePermission.data,
                ],
              },
        }))
        if(addedSpacePermission.success === true){
            alert('Invitation send to user');
        }

    }

    async getAccessMembers(spaceId){

        const dashboardHandler = new functions();
        const result = await dashboardHandler.getAccessMembers(spaceId);

        this.setState({spacePermissionList: result});

    }

    async handleSelectedRole(role, permissionId){
        console.log(role, permissionId);
        let data = {
            Id: permissionId,
            accessType: role
        }
        const dashboardHandler = new functions();
        const result = await dashboardHandler.changeSpacePermission(data);
        console.log(result);
    }

    async handlePublishDomain(e, subdomain){
        e.preventDefault();

        await this.setState({showSubDomainError: ''});
        const data = {
            spaceId: this.state.navItemRefactored.currentSpaceId,
            subDomain: subdomain,
            customDomain: 'www.google.com'
        }

        if(stringUtilsImpl.isUndefinedEmptyorNull(data.spaceId) && stringUtilsImpl.isUndefinedEmptyorNull(data.subDomain)){
            await this.setState({showSubDomainError: 'Please select the space first to publish'})
            return;
        }

        const dashboardHandler = new functions();
        const result = await dashboardHandler.handlePublishDomain(data);
        if(!result.success){
            await this.setState({showSubDomainError: result.msg});
        }
        else if(result.success){
            alert('Subdomain registered');
        }
    }

    render() {
        return (
            <>
                <section className="ui-section">
                    <div className="ui-left">
                        <InputSidebar 
                            onBlur={this.setOrganizationName} 
                            inputChanged={undefined} 
                            user = {this.state.user}
                            createSpace = {this.createSpace}
                            handleCurrentSpaceId = {this.handleCurrentSpaceId}
                            selectedSpaceId={this.state.navItemRefactored.currentSpaceId}
                        />
                    </div>
                    <div className="ui-right">
                        <InputTopbar 
                            onBlur={this.handleSpaceDocumentationChange}
                            onHamburgerClick = {this.toggleSubsidebar}
                            onSave = {this.savePagetoDB}
                            onPreview = {this.state.navItemRefactored.currentSpaceId}
                            changeTitle = {this.state.navItemRefactored.currentSpaceName}
                        />
                        <div className="ui-right-bottom">
                            <SubSidebar
                                isOpen = {this.state.isSubsidebarOpen}
                                addNewPage = {this.addNewPage}
                                addNewGroup = {this.addNewGroup}
                                addGroupPage = {this.addGroupPage}
                                navigations = {this.state.navItemRefactored}
                                pageNumber={this.state.navItemRefactored.pageIndex}
                                renameGroup={this.renameGroup}
                                openDialogBoxForGroup={this.openDialogBoxForGroup}
                                renamePage={this.renamePage}
                                openDialogBoxForPage={this.openDialogBoxForPage}
                                handleCurrentPage = {this.handleCurrentPage}
                                selectedPage = {this.state.navItemRefactored.currentPage}
                            />
                            <InputArea
                                editorContent={this.saveContent}
                                currentPage={this.state.navItemRefactored.currentPage}
                                prevBtn={this.handleCurrentPage}
                                nextBtn={this.handleCurrentPage}
                            />
                        </div>
                    </div>

                    <Overlay
                        label="Enter New Group Name"
                        className="rename-nav"
                        overlayBtn='Rename'
                        hideDialogBox={this.hideDialogBox}
                        newElementName={this.renameGroup}
                    />

                    <Overlay
                        label='Enter Page Name'
                        className='rename-page'
                        overlayBtn='Rename'
                        hideDialogBox={this.hideDialogBox}
                        newElementName = {this.renamePage}
                    />

                    <ShareOverlay 
                        adminUser={this.state.spacePermissionList.adminUser}
                        searchUser={this.searchUser}
                        userSearchList={this.state.userSearched}
                        navItemRefactored={this.state.navItemRefactored}
                        sendInviteLink={this.sendInviteLink}
                        spacePermissionList={this.state.spacePermissionList}
                        handleSelectedRole = {this.handleSelectedRole}
                        handleSubDomain= {this.handlePublishDomain}
                        showError={this.state.showSubDomainError}
                    />

                    <SavedOverlay 
                        className='save-page'
                        hideDialogBox={this.hideDialogBox}
                        overlayBtn='Save'
                        overlayBtn2='Discard'
                        saveChange={undefined}
                        discardChange={undefined}
                    />

                    <Reloader
                        openReloader = {this.state.showReloader}
                    />

                </section>
            </>
        )
    }
}
