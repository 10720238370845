import React, { Component } from 'react'

import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';
import Editorjs from 'lib/EditorJs/Editorjs';

import './InputArea.css'
import spaceDocumentationInterface from 'data/spaceDocumentation/spaceDocumentationInterface';


interface props{
    editorContent: any
    currentPage: spaceDocumentationInterface
    prevBtn: any
    nextBtn: any
}
interface state {
    ejInstance: any
}

export default class InputArea extends Component<props, state> {

    render() {
        return (
        <>
            
                <div className={!stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage)  ? "ia-container-show":"ia-container"}>

                    <div className="text-editor-container">
                        <Editorjs 
                            editorContent={this.props.editorContent}
                            currentPage={this.props.currentPage}
                            isReadOnly = {false}
                        />
                    </div>

                    {
                        !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage) ?
                        <>
                            <div className="movepage-btn">
                                <div className={stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getprevPage()) ? "movepage-left-disabled" : "movepage-left"}  onClick={(e)=> this.props.prevBtn(e, this.props.currentPage.getprevPage())}>
                                    <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 16 16"><path fill="#5b6975" fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/></svg>
                                    </div>
                                    <div className='btn-text-left'>
                                        <p>
                                            {
                                                !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage) &&
                                                !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getprevPage()) &&
                                                !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getprevPage().getgroupName()) ? 
                                                
                                                this.props.currentPage.getprevPage().getgroupName() : 
                                                <></>
                                                 
                                            } 
                                            
                                            Previous
                                        </p>
                                        <p>
                                            {
                                                !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage) &&
                                                !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getprevPage()) &&
                                                !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getprevPage().getpageName()) ? 
                                                
                                                this.props.currentPage.getprevPage().getpageName() : 
                                                this.props.currentPage.getpageName()
                                                 
                                            } 
                                        </p>
                                    </div>
                                </div>
                                <div className={stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getnextPage()) ? "movepage-right-disabled" : "movepage-right"} onClick={(e)=> this.props.nextBtn(e, this.props.currentPage.getnextPage())}>
                                    <div className='btn-text-right'>
                                        <p>
                                        {
                                                !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage) &&
                                                !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getnextPage()) &&
                                                !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getnextPage().getgroupName()) ? 
                                                
                                                this.props.currentPage.getnextPage().getgroupName() : 
                                                <></>
                                                 
                                        } 
                                            
                                        Next</p>
                                        <p>
                                            {
                                                !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage) &&
                                                !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getnextPage()) &&
                                                !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getnextPage().getpageName()) ? 
                                                
                                                this.props.currentPage.getnextPage().getpageName() : 
                                                this.props.currentPage.getpageName()
                                                 
                                            } 
                                        </p>
                                    </div>
                                    <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 16 16"><g transform="translate(16 0) scale(-1 1)"><path fill="#5b6975" fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/></g></svg>
                                    </div>
                                    
                                </div>
                            </div>
                        </>
                        :
                        <></>
                    }
                </div>
                
                
                    <div className={stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage)  ? "no-editor-content":"no-editor-content-hide"}>
                        <div className="editor-msg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 15 15"><path fill="none" stroke="#8898a7" d="m9.5 5.5l-4 4m5-9h-8a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-10l-3-3Zm-3 10a3 3 0 1 1 0-6a3 3 0 0 1 0 6Z"/></svg>
                            <p>Create New Page <br></br> <span>or</span> <br></br>  Select any Page to Edit</p>
                        </div>
                    </div>

        </>
        )
    }
}
