let newGroupJson  = {
    Id: "",
    title: "",
    pageName: "Page",
    content: "",
    sequenceNumber: 5,
    spaceId: "",
    groupName: "Group",
    moreInfo: "",
}

let newPageJson = {
    Id: "",
    title: "",
    pageName: "Page",
    content: "",
    sequenceNumber: 5,
    spaceId: "",
    groupName: undefined,
    moreInfo: "",
}

export {
    newGroupJson,
    newPageJson
}