import userFormInterface from "data/user/userFormInterface";
import spacePermissionInterface from "./spacePermissionInterface";
import stringUtilsImpl from "utils/stringUtils/stringUtilsImpl";
import userFormImpl from "data/user/userFormImpl";

export default class spacePermissionImpl implements spacePermissionInterface{
    Id: string;
    user: userFormInterface
    accessType: string;
    createdAt: string;
    updatedAt: string;

    initfromDataObj(data) {
        this.Id = data.Id;
        this.accessType = data.accessType;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;

        if(!stringUtilsImpl.isUndefinedEmptyorNull(data.user)) {
            const userFormHandler = new userFormImpl();
            userFormHandler.initFromDataObj(data.user);
            this.user = userFormHandler
        }
    }
}