import pageSEOImpl from "data/SEO/pageSEO/pageSEOImpl";
import helperSpaceDocumentationImpl from "data/helperSpaceDocumentation/helperSpaceDocumentationImpl";
import userFormImpl from "data/user/userFormImpl";
import spacePermissionImpl from "data/spacePermission/spacePermissionImpl";
import axiosCall from "lib/axios";
import ApiRouteMapping from "utils/AllApiRouteMapping";
import PageSEOData from "utils/AllPageSEOData";
import helperFunctionsImpl from "utils/helperFunctionsUtils/helperFunctionsImpl";
import stringUtilsImpl from "utils/stringUtils/stringUtilsImpl";
import spaceImpl from "data/space/spaceImpl";


export default class functions {

    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.Login;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl())
        return pageSEO;
    }

    async getAllDetails() {
        // Give us all Data like Organizations array, spaces array, and the content for spaces 

        let result = await axiosCall(ApiRouteMapping.getAllDetails.apiPath, ApiRouteMapping.getAllDetails.method, null, null, null);
        const user = new userFormImpl();
        user.initFromDataObj(result.data.user);
        return user;
    }

    async saveOrganizationName(dataJson, Id) {
        const payload = dataJson;
        let params = { organizationId: Id}
        let result = await axiosCall(ApiRouteMapping.updateOrganizationName.apiPath, ApiRouteMapping.updateOrganizationName.method, params, null, payload);
        return result;
    }

    async createSpace(dataJson) {
        const payload = dataJson;
        let result = await axiosCall(ApiRouteMapping.createSpace.apiPath, ApiRouteMapping.createSpace.method, null, null, payload);
        return result;
    }

    async savetoDB(dataJson){
        const helperSpaceDocumentationHandler = new helperSpaceDocumentationImpl();
        helperSpaceDocumentationHandler.initDataFromObj(dataJson);
        let json = helperSpaceDocumentationHandler.convertToArrayOfSpaceDocumentationInterface();
        let payload = {
            spacePages: json
        }
        let result = await axiosCall(ApiRouteMapping.createPage.apiPath, ApiRouteMapping.createPage.method, null, null, payload);
        return result;
    }

    async addNewGroup(newGroup, dataJson) {
        const helperSpaceDocumentationHandler = new helperSpaceDocumentationImpl();
        helperSpaceDocumentationHandler.initDataFromObj(dataJson)
        helperSpaceDocumentationHandler.addNewGroup(newGroup);
        return helperSpaceDocumentationHandler;
    }   

    async addNewPage(newPage, dataJson){
        const helperSpaceDocumentationHandler = new helperSpaceDocumentationImpl();
        helperSpaceDocumentationHandler.initDataFromObj(dataJson)
        helperSpaceDocumentationHandler.addNewPage(newPage);
        return helperSpaceDocumentationHandler;
    }

    async addGroupPage(newGroupPage, dataJson){
        const helperSpaceDocumentationHandler = new helperSpaceDocumentationImpl();
        helperSpaceDocumentationHandler.initDataFromObj(dataJson)
        helperSpaceDocumentationHandler.addNewGroupPage(newGroupPage);
        return helperSpaceDocumentationHandler;
    }

    async renameGroup(newGroupName, dataJson) {
        const helperSpaceDocumentationHandler = new helperSpaceDocumentationImpl();
        helperSpaceDocumentationHandler.initDataFromObj(dataJson)
        helperSpaceDocumentationHandler.renameGroup(newGroupName);
        return helperSpaceDocumentationHandler
    }

    async renamePage(newPageName, dataJson){
        const helperSpaceDocumentationHandler = new helperSpaceDocumentationImpl();
        helperSpaceDocumentationHandler.initDataFromObj(dataJson);
        helperSpaceDocumentationHandler.renamePage(newPageName);
        return helperSpaceDocumentationHandler
    }

    async saveContent(content, dataJson){
        const helperSpaceDocumentationHandler = new helperSpaceDocumentationImpl();
        helperSpaceDocumentationHandler.initDataFromObj(dataJson);
        helperSpaceDocumentationHandler.saveContent(content);
        return helperSpaceDocumentationHandler
    }

    async refactorSpaceDocumentation(result,  spaceId, spaceName){
        const helperSpaceDocumentationHandler = new helperSpaceDocumentationImpl();
        helperSpaceDocumentationHandler.initFromspaceDocumentation(result);
        helperSpaceDocumentationHandler.setcurrentSpaceId(spaceId);
        helperSpaceDocumentationHandler.setcurrentSpaceName(spaceName);
        return helperSpaceDocumentationHandler;
    }

    async updateSubSidebar(dataJson, spaceId, spaceName){
        if(stringUtilsImpl.isUndefinedEmptyorNull(dataJson)){
           return;
        }

        const payload = {currentSpaceId : dataJson.currentSpaceId};
        let result = await axiosCall(ApiRouteMapping.updateSubSidebar.apiPath, ApiRouteMapping.updateSubSidebar.method, null, null, payload);

        const refactorSpaceDocumentation = this.refactorSpaceDocumentation(result.data.pages,  spaceId, spaceName);
        return refactorSpaceDocumentation
    }

    async updateEditorContent(dataJson){

        const payload = {pageId : dataJson.currentPage.Id};
        let result = await axiosCall(ApiRouteMapping.updateEditorContent.apiPath, ApiRouteMapping.updateEditorContent.method, null, null, payload);
        const helperSpaceDocumentationHandler  = new helperSpaceDocumentationImpl();
        helperSpaceDocumentationHandler.initDataFromObj(dataJson);
        helperSpaceDocumentationHandler.saveContent(result.data.content);
        return helperSpaceDocumentationHandler;
    }
    
    async searchUserForInvite(dataJson){
        const params = {userQuery: dataJson};
        let users = await axiosCall(ApiRouteMapping.searchUserForInvite.apiPath, ApiRouteMapping.searchUserForInvite.method, params, null, null);
        
        let userArray = []
        for(let i=0; i<users.data.user.length; i++){
            const user = new userFormImpl();
            user.initFromDataObj(users.data.user[i]);
            userArray.push(user);
        }
        
        return userArray;
    }

    async saveInviteLink(dataJson){
        const payload = dataJson
        let result = await axiosCall(ApiRouteMapping.saveInviteLink.apiPath, ApiRouteMapping.saveInviteLink.method, null, null, payload);
        
        if(result.data.success !== true){
            return result.data;
        }

        const spacePermissionHandler = new spacePermissionImpl();
        spacePermissionHandler.initfromDataObj(result.data.spacePermission);
        let returnJson = {
            success: result.data.success,
            data: spacePermissionHandler
        }
        return returnJson;

    }

    async getAccessMembers(dataJson){
        let params = {
            spaceId : dataJson
        };
        let result = await axiosCall(ApiRouteMapping.fetchAccessMembers.apiPath, ApiRouteMapping.fetchAccessMembers.method, params, null, null);

        const spaceData = new spaceImpl();
        spaceData.setadminUser(result.data.adminUser);
        spaceData.setmanageUser(result.data.spacePermission);

        return spaceData;
    }

    async changeSpacePermission(dataJson){
        const payload = dataJson;
        let result = await axiosCall(ApiRouteMapping.changeSpacePermission.apiPath, ApiRouteMapping.changeSpacePermission.method, null, null, payload);

        return result.data;
    }

    async handlePublishDomain(dataJson){
        const payload = dataJson;
        let result = await axiosCall(ApiRouteMapping.publishSubDomain.apiPath, ApiRouteMapping.publishSubDomain.method, null, null, payload);
        
        return result.data;
    }
}