import spaceInterface from 'data/space/spaceInterface';
import organizationalInterface from './organizationInterface';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';
import spaceImpl from 'data/space/spaceImpl';

export default class organizationImpl implements organizationalInterface {
    Id: string;
    organizationName: string;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    spaces: spaceInterface[] = [];

    initfromDataObj(data) {
        this.Id = data.Id;
        this.organizationName = data.organizationName;
        this.createdBy = data.createdBy;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;

        if (!stringUtilsImpl.isUndefinedEmptyorNull(data.spaces)) {
            for (let i = 0; i < data.spaces.length; i++) {
                let spacesObj = new spaceImpl();
                spacesObj.initfromDataObj(data.spaces[i]);
                this.spaces.push(spacesObj)
            }
        }
    }

    setorganizationName(organizationName) {
        this.organizationName = organizationName;
    }
}