import React, { Component } from 'react'
import './SavedOverlay.css'

interface props{
    className: string
    overlayBtn: string
    overlayBtn2: string
    hideDialogBox: any
    saveChange: any
    discardChange: any
}

export default class SavedOverlay extends Component<props, any> {
  render() {
    return (
      <>
        <div className={"saved-overlay-container " + this.props.className}>
            <div className="saved-overlay-wrapper">
                <div className="saved-overlay-box">
                        <form onSubmit={undefined}>
                        <div>
                            <div className="close-btn">
                                <div className="close-btn-icon" onClick={this.props.hideDialogBox}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 36 36"><path fill="#3b454e" d="m19.41 18l8.29-8.29a1 1 0 0 0-1.41-1.41L18 16.59l-8.29-8.3a1 1 0 0 0-1.42 1.42l8.3 8.29l-8.3 8.29A1 1 0 1 0 9.7 27.7l8.3-8.29l8.29 8.29a1 1 0 0 0 1.41-1.41Z"/><path fill="none" d="M0 0h36v36H0z"/></svg>
                                </div>
                            </div>
                            <p className='saved-overlay-text'>You have unsaved changes ?</p>
                        </div>
                        <div>
                            <div className='saved-overlay-btn-box'>
                                <button className="discard-space margin-top-20" type="submit">
                                    <p>{this.props.overlayBtn2}</p>
                                </button>
                                <button className="save-space " type="submit">
                                    <p>{this.props.overlayBtn}</p>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </>
    )
  }
}
