import React, { Component } from 'react'
import "./Reloader.css"

interface props{
    openReloader: boolean
}

export default class Reloader extends Component<props, any> {
  render() {
    return (
      <>
        <div className={this.props.openReloader ? "rl-container show-reloader" : "rl-container"}>
            <div className="rl-box">
                <div className="rl-outer">
                </div>
            </div>
        </div>

      </>
    )
  }
}
