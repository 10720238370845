import React, { Component } from 'react'

import './Content.css'

import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';
import spaceDocumentationInterface from 'data/spaceDocumentation/spaceDocumentationInterface';
import Editorjs from 'lib/EditorJs/Editorjs';

interface props {
    currentPage: spaceDocumentationInterface
    prevBtn: any
    nextBtn: any,
}
export default class Content extends Component<props,{}> {


    render() {
        return (
        <>
            <section className="content-section">
                <div className="content-container">
                    {
                        !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage) ?
                        
                        <Editorjs 
                            editorContent={undefined} 
                            currentPage={this.props.currentPage}
                            isReadOnly = {true}
                        />

                        :
                        <></>
                    }

                        {
                            !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage) ?
                            <>
                                <div className="movepage-btn">
                                    <div className={stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getprevPage()) ? "movepage-left-disabled" :        "movepage-left"}  onClick={(e)=> this.props.prevBtn(e, this.props.currentPage.getprevPage())}>
                                        <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 16 16"><path fill="#5b6975" fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/></svg>
                                        </div>
                                        <div className='btn-text-left'>
                                            <p>
                                                {
                                                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage) &&
                                                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getprevPage()) &&
                                                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getprevPage().getgroupName()) ? 
                                                    
                                                    this.props.currentPage.getprevPage().getgroupName() : 
                                                    <></>
                                                    
                                                } 
                                                
                                                Previous
                                            </p>
                                            <p>
                                                {
                                                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage) &&
                                                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getprevPage()) &&
                                                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getprevPage().getpageName()) ? 
                                                    
                                                    this.props.currentPage.getprevPage().getpageName() : 
                                                    this.props.currentPage.getpageName()
                                                    
                                                } 
                                            </p>
                                        </div>
                                    </div>
                                    <div className={stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getnextPage()) ? "movepage-right-disabled" : "movepage-right"} onClick={(e)=> this.props.nextBtn(e, this.props.currentPage.getnextPage())}>
                                        <div className='btn-text-right'>
                                            <p>
                                            {
                                                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage) &&
                                                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getnextPage()) &&
                                                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getnextPage().getgroupName()) ? 
                                                    
                                                    this.props.currentPage.getnextPage().getgroupName() : 
                                                    <></>
                                                    
                                            } 
                                                
                                            Next</p>
                                            <p>
                                                {
                                                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage) &&
                                                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getnextPage()) &&
                                                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage.getnextPage().getpageName()) ? 
                                                    
                                                    this.props.currentPage.getnextPage().getpageName() : 
                                                    this.props.currentPage.getpageName()
                                                    
                                                } 
                                            </p>
                                        </div>
                                        <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 16 16"><g transform="translate(16 0) scale(-1 1)"><path fill="#5b6975" fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/></g></svg>
                                        </div>
                                        
                                    </div>
                                </div>
                            </>
                            :
                            <></>
                        }
                    

                </div>

            </section>
        </>
        )
    }
}
