import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from 'pages/Login/Login';
import Dashboard from 'pages/Dashboard/Dashboard';
import Preview from 'pages/Preview/Preview'


import './App.css'
import Pricing from 'pages/Pricing/Pricing';


function App() {
  return (
       <Router>
          <Routes>
              <Route path="/pricing" Component={Pricing} />
              <Route path="/preview/:spaceId" Component={Preview}  />
              <Route path="/dashboard" Component={Dashboard} />
              <Route path="/" Component= {Login} />
          </Routes>
       </Router>
  );
}

export default App;
