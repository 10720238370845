import ApiRouteMapping from "utils/AllApiRouteMapping";
import axiosCall from "lib/axios";
import pricingData from "utils/pricingData";


export default class functions{
    async getStripeProductsDetails() {
        try {
            let result = await axiosCall(ApiRouteMapping.getStripeProducts.apiPath, ApiRouteMapping.getStripeProducts.method, null, null, null);

            for(let i=0; i<result.data.data.length; i++){
                if(result.data.data[i].nickname === 'Basic'){
                    pricingData.monthly.basic.id = result.data.data[i].id;
                    pricingData.monthly.basic.amount = result.data.data[i].unit_amount / 100;
                }
                else if(result.data.data[i].nickname === 'Pro'){
                    pricingData.monthly.pro.id = result.data.data[i].id;
                    pricingData.monthly.pro.amount = result.data.data[i].unit_amount / 100;

                }
                else if(result.data.data[i].nickname === 'Premium'){
                    pricingData.monthly.premium.id = result.data.data[i].id;
                    pricingData.monthly.premium.amount = result.data.data[i].unit_amount / 100;

                }
            }
            return pricingData;

        } catch(e: any) {
            const response = e;
            return response;
        }    
    }


    async checkoutSubscription(data: any){
        try {
            const payload  = data;
            const response = await axiosCall(ApiRouteMapping.checkoutProduct.apiPath, ApiRouteMapping.checkoutProduct.method, null, null, payload);
            return response.data;
        } catch(e: any) {
            const response = e;
            return response;
        }    
    }
}