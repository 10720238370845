import React, {useCallback, useState, useEffect} from 'react'
import ReactFlow, {
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    Controls,
    ControlButton,
    // useReactFlow,
    ReactFlowProvider
  } from 'reactflow';

import 'reactflow/dist/style.css';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';

interface props{
    onDataChange: any,
    readOnly: any,
    saveCallback: any,
    data: any
}

let nodeId = 3;
let xCoordinate = 0;
let yCoordinate = 0;
const initialNodes = [
  { id: '1', position: { x: 0, y: 40 }, data: { label: '1' } },
  { id: '2', position: { x: 0, y: 140 }, data: { label: '2' } },
];
const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];

export default function App(props : props) {
    
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [selectedNodeId, setSelectedNodeId] = useState('1');
    const [nodeData, setNodeData] = useState('1');
    const [rfInstance, setRfInstance] = useState(null);
    // const { setViewport } = useReactFlow();

    useEffect(() => {

        
       
        if (rfInstance && !stringUtilsImpl.isUndefinedEmptyorNull(props?.onDataChange)) {
            setNodes((nds) =>
            nds.map((node) => {
              if (node.id === selectedNodeId) {
                node.data = {
                  ...node.data,
                  label: nodeData,
                };
              }
      
              return node;
            })
          );
            let finalData = rfInstance.toObject();
            props?.onDataChange(finalData);
            return;
        }

        if(!stringUtilsImpl.isUndefinedEmptyorNull(props.data)) {
            onRestore();
        }

        
    }, [rfInstance, nodeData, setNodes, selectedNodeId]);

    
    // const save = useCallback(() => {
    //     if (rfInstance) {
    //         this.props.data = rfInstance.toObject();
    //         return this.props.data
    //     }
    // }, [rfInstance]);
    const onRestore = useCallback(() => {
        const restoreFlow = async () => {
          const flow = props.data;
    
          if (flow) {
            const { x = 0, y = 0, zoom = 1 } = flow.viewport;
            setNodes(flow.nodes || []);
            setEdges(flow.edges || []);
            // setViewport({ x, y, zoom });
          }
        };
    
        restoreFlow();
      }, [setNodes]);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

    function addNewNode(){
        const newNode = {
            id: String(nodeId),
            position: { x: xCoordinate, y: yCoordinate },
            data: { label: String(nodeId) },
        };
        setNodes(prevState => [
            ...prevState,
            newNode
        ]);
        nodeId++;
        xCoordinate += 5;
        yCoordinate += 5;
    }

    const handleSelectedNode = (event, node) => {
        setSelectedNodeId(node.id);
        setNodeData(node.data.label);
    }

    return (
        <ReactFlowProvider>
        <div style={{ width: '100%', height: '60vh', boxShadow: '0px 0px 10px grey inset', padding: '10px', borderRadius: '6px' }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onNodeDragStart={handleSelectedNode}
                onInit={setRfInstance}
            >
                <Background gap={12} size={1} />
                <Controls showZoom={false}>
                    <ControlButton onClick={() => addNewNode()} title="addNode">
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 256 256"><g id="galaAdd0" fill="none" stroke="currentColor" stroke-dasharray="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="4" stroke-opacity="1" stroke-width="16"><circle id="galaAdd1" cx="128" cy="128" r="112"/><path id="galaAdd2" d="M 79.999992,128 H 176.0001"/><path id="galaAdd3" d="m 128.00004,79.99995 v 96.0001"/></g></svg></div>
                    </ControlButton>
                </Controls>
            </ReactFlow>
            <div>
                <input type="text" value={nodeData} onChange={(e)=> setNodeData(e.target.value)} name="" id="" style={{position: 'absolute', top: '0px', right:`calc(0px)`, padding:".5rem", border: '1.5px solid #ccc', borderBottomLeftRadius:'4px', borderTopRightRadius:'4px', outline: 'none'}} />
            </div>
        </div>
        </ReactFlowProvider>
      );
}