import config from "config";
const ApiRouteMapping = {
    updateOrganizationName: {
        apiPath: '/organization/update',
        method: 'POST',
    },

    getAllDetails: {
        // Give us all Data like Organizations array, spaces array, and the content for spaces 
        apiPath: '/user/getData',
        method: 'GET',
    },

    createSpace: {
        apiPath: '/space/create',
        method: 'POST'
    },

    createPage: {
        apiPath: '/spaceDocumentation/create',
        method: 'POST'
    },
    
    updateSubSidebar: {
        apiPath: '/spaceDocumentation/getSpacePages',
        method: 'POST'
    },
    updateEditorContent: {
        apiPath: '/spaceDocumentation/updateContent',
        method: 'POST'
    },
    previewContent: {
        apiPath: '/spaceDocumentation/getSpacePages',
        method: 'POST'
    },
    previewPageContent: {
        apiPath: '/spaceDocumentation/updateContent',
        method: 'POST'
    },

    imageAuthoziationToken: {
        apiPath: '/image/auth',
        method: 'POST'
    },
    fetchLinkData: {
        apiPath: '/metadata/fetchUrl',
        url: config.app.url + '/metadata/fetchUrl'
    },
    searchUserForInvite: {
        apiPath: '/user/searchUser',
        method: 'GET'
    },
    saveInviteLink: {
        apiPath: '/spacePermission/invite',
        method: 'POST'
    },
    fetchAccessMembers: {
        apiPath: '/spacePermission/getPermission',
        method: 'GET'
    },
    changeSpacePermission: {
        apiPath: '/spacePermission/changePermission',
        method: 'POST'
    },
    getStripeProducts: {
        apiPath: '/stripe/getStripeProducts',
        method: 'GET'
    },
    checkoutProduct: {
        apiPath: '/stripe/checkoutProduct',
        method: 'POST'
    },
    publishSubDomain: {
        apiPath: '/spaceDomain/subDomain/publish',
        method: 'POST'
    }
}

export default ApiRouteMapping;