import React, { Component } from 'react'
import NewPage from 'components/NewPage/NewPage'

import './NewGroup.css'
import $ from 'jquery';
import spaceDocumentationInterface from 'data/spaceDocumentation/spaceDocumentationInterface';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';


interface props{
  pages: spaceDocumentationInterface[]
  renameGroup: any
  addGroupPage: any
  openDialogBoxForGroup: any
  renamePage: any
  openDialogBoxForPage: any
  handleCurrentPage: any
  selectedPage: any
}

interface state{
  openDropdown: boolean,
}

export default class NewGroup extends Component<props, state> {
  constructor(props){
    super(props);

    this.openGrpMenu = this.openGrpMenu.bind(this);

    this.state = {
      openDropdown: false
    }
  }

  openGrpMenu(e){
    e.preventDefault();
    $(e.currentTarget).addClass('grp-menu-active');
    this.setState((prevState) => ({
      openDropdown: !prevState.openDropdown
    }))
  }

  render() {
    return (
      <>
        <div className="grp-heading">
          {
            !stringUtilsImpl.isUndefinedEmptyorNull(this.props.pages) && this.props.pages.length > 0 && !stringUtilsImpl.isUndefinedEmptyorNull(this.props.pages[0].groupName) ?
              <p className='grp-name'>{this.props.pages[0].groupName}</p>
              :
              <></>
          }
          
          <div className='grp-menu-icon' onClick={this.openGrpMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
                  <path
                    fill="black"
                    d="M9 15.25a1.25 1.25 0 1 1 2.5 0a1.25 1.25 0 0 1-2.5 0Zm0-5a1.25 1.25 0 1 1 2.5 0a1.25 1.25 0 0 1-2.5 0Zm0-5a1.249 1.249 0 1 1 2.5 0a1.25 1.25 0 1 1-2.5 0Z"
                  />
              </svg>
          </div>
          <div className={+ this.state.openDropdown ? "grp-menu grp-menu-active" : "grp-menu "} >
              <p onClick={(e) => this.props.openDialogBoxForGroup(e, this.props.pages[0].groupName)} ><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="none" stroke="#3b454e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m5 16l-1 4l4-1L19.586 7.414a2 2 0 0 0 0-2.828l-.172-.172a2 2 0 0 0-2.828 0L5 16ZM15 6l3 3m-5 11h8"/></svg></span>Rename</p>

              <p onClick={(e) => this.props.addGroupPage(e, this.props.pages[0].groupName)}><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 256 256"><g id="galaAdd0" fill="none" stroke="#3b454e" stroke-dasharray="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="4" stroke-opacity="1" stroke-width="16"><circle id="galaAdd1" cx="128" cy="128" r="112"/><path id="galaAdd2" d="M 79.999992,128 H 176.0001"/><path id="galaAdd3" d="m 128.00004,79.99995 v 96.0001"/></g></svg></span> Add New Page </p>
          </div>
        </div>
        {
          this.props.pages.map((page) => {
            return(
              <NewPage  
                page = {page} renamePage={this.props.renamePage} 
                openDialogBoxForPage={this.props.openDialogBoxForPage}
                handleCurrentPage={this.props.handleCurrentPage}
                selectedPage = {this.props.selectedPage}
              />
            )
          })
        }
        <div className="page-line"></div>

      </>
    )
  }
}
