import organizationInterface from "data/organization/organizationInterface";
import stringUtilsImpl from "utils/stringUtils/stringUtilsImpl";
import userFormInterface from "./userFormInterface";
import organizationImpl from "data/organization/organizationImpl";

export default class userFormImpl implements userFormInterface {
    Id: string;
    firstname: string;
    lastname: string;
    email: string;
    image: string;
    loginFrom: string;
    subscriptionType: string;
    createdAt: string;
    updatedAt: string;

    organizations: organizationInterface[] = [];

    initFromDataObj(data) {

        if(stringUtilsImpl.isUndefinedEmptyorNull(data)) {
            return;
        }

        this.Id = data.Id;
        this.firstname = data.firstname;
        this.lastname = data.lastname;
        this.email = data.email;
        this.image = data.image;
        this.loginFrom = data.loginFrom;
        this.subscriptionType = data.subscriptionType;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        
        if (!stringUtilsImpl.isUndefinedEmptyorNull(data.organizations)) {
            for (let i = 0; i < data.organizations.length; i++) {
                let organizationObj = new organizationImpl();
                organizationObj.initfromDataObj(data.organizations[i]);
                this.organizations.push(organizationObj)
            }
        }
    }
}