import React, { Component } from "react";

import "./SubSidebar.css";
import $ from 'jquery';

import NewPage from "components/NewPage/NewPage";
import NewGroup from "components/NewGroup/NewGroup";

import helperSpaceDocumentationInterface from "data/helperSpaceDocumentation/helperSpaceDocumentationInterface";
import stringUtilsImpl from "utils/stringUtils/stringUtilsImpl";


interface props{
    isOpen: boolean
    addNewPage: any
    addNewGroup: any
    addGroupPage: any
    navigations: helperSpaceDocumentationInterface
    pageNumber: number
    renameGroup: any
    openDialogBoxForGroup: any
    renamePage: any
    openDialogBoxForPage: any
    handleCurrentPage: any
    selectedPage: any
}


export default class SubSidebar extends Component<props, any> {
    constructor(props){
        super(props);

        this.addPageMenu = this.addPageMenu.bind(this);
    }


    addPageMenu(e){
        $('.addpage-menu').toggleClass('addpage-menu-active');
    }

    

  render() {
    return (
      <>
        <div className={`ssb-container ${this.props.isOpen ? 'open-ssb-container' : ''}`}>
            <div className="ssb-container-top">
                {
                    !stringUtilsImpl.isUndefinedEmptyorNull(this.props.navigations) && !stringUtilsImpl.isUndefinedEmptyorNull(this.props.navigations.navItems) ?
                        <>
                        {
                            this.props.navigations.navItems.map((navigation) => {

                                if(navigation.length > 0 && navigation[0].getgroupName() !== undefined ) {
                                    return(
                                        <NewGroup 
                                            pages = {navigation}
                                            renameGroup={this.props.renameGroup}
                                            openDialogBoxForGroup={this.props.openDialogBoxForGroup}
                                            renamePage={this.props.renamePage}
                                            openDialogBoxForPage={this.props.openDialogBoxForPage}
                                            handleCurrentPage={this.props.handleCurrentPage}
                                            selectedPage = {this.props.selectedPage}
                                            addGroupPage  = {this.props.addGroupPage}
                                        />
                                    )
                                } else {
                                    return(
                                        <NewPage 
                                            page={navigation[0]}
                                            renamePage={this.props.renamePage} 
                                            openDialogBoxForPage={this.props.openDialogBoxForPage}
                                            handleCurrentPage={this.props.handleCurrentPage}
                                            selectedPage = {this.props.selectedPage}
                                        />
                                    )
                                }   

                                
                            })
                        }
                        </>
                    :
                    <></>
                }
                

            </div>
            
            
            <div className="page-line"></div>
            <div className={stringUtilsImpl.isUndefinedEmptyorNull(this.props.navigations.currentSpaceId) ? "ssb-container-bottom-hide":"ssb-container-bottom"} onClick={this.addPageMenu}>
                <div className="add-page" >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><g fill="none" stroke="#5b6975" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M7 2h9.5L21 6.5V19"/><path d="M3 20.5v-14A1.5 1.5 0 0 1 4.5 5h9.752a.6.6 0 0 1 .424.176l3.148 3.148A.6.6 0 0 1 18 8.75V20.5a1.5 1.5 0 0 1-1.5 1.5h-12A1.5 1.5 0 0 1 3 20.5Z"/><path d="M14 5v3.4a.6.6 0 0 0 .6.6H18"/></g></svg>
                    <p>New Page</p>
                </div>
                <div className="addpage-menu">
                    <p onClick={this.props.addNewPage}><span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><g fill="none" stroke="#5b6975" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M4 21.4V2.6a.6.6 0 0 1 .6-.6h11.652a.6.6 0 0 1 .424.176l3.148 3.148A.6.6 0 0 1 20 5.75V21.4a.6.6 0 0 1-.6.6H4.6a.6.6 0 0 1-.6-.6ZM8 10h8m-8 8h8m-8-4h4"/><path d="M16 2v3.4a.6.6 0 0 0 .6.6H20"/></g></svg></span>New Document Page</p>

                    <p onClick={this.props.addNewGroup} ><span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><g fill="none" stroke="#5b6975" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M7 2h9.5L21 6.5V19"/><path d="M3 20.5v-14A1.5 1.5 0 0 1 4.5 5h9.752a.6.6 0 0 1 .424.176l3.148 3.148A.6.6 0 0 1 18 8.75V20.5a1.5 1.5 0 0 1-1.5 1.5h-12A1.5 1.5 0 0 1 3 20.5Z"/><path d="M14 5v3.4a.6.6 0 0 0 .6.6H18"/></g></svg></span>New Group</p>

                    <p><span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><g fill="none" stroke="#5b6975" stroke-linecap="round" stroke-width="2"><path d="m12 17l-1.5 1.5a3.536 3.536 0 0 1-5 0v0a3.536 3.536 0 0 1 0-5l3-3a3.536 3.536 0 0 1 5 0v0"/><path d="m12 7l1.5-1.5a3.536 3.536 0 0 1 5 0v0a3.536 3.536 0 0 1 0 5l-3 3a3.536 3.536 0 0 1-5 0v0"/></g></svg></span>New Link</p>
                </div>
            </div>

        </div>
      </>
    );
  }
}
