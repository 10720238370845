import helperFunctionsInterface from "./helperFunctionsInterface";

export default class helperFunctionsImpl implements helperFunctionsInterface {
    static createsrcSet(path, isDesignLanding = false) {
        if (isDesignLanding) {
            return path + " 500w, " + path + " 800w, " + path + " 1080w, " + path + " 1600w, " + path + " 2000w";
        }
        return path + " 500w, " + path + " 800w, " + path + " 1080w, " + path + " 1200w";
    }   

    static createsrcSetforcarousel(path) {
        return path + " 500w, " + path + " 800w, " + path + " 1080w, " + path + " 1600w, " + path + " 2000w " + path + " 2600w, " + path + " 3200w";
    }

    static getCurrentUrl() {
        return window.location.href;
    }
}