import axiosCall from "lib/axios";
import ApiRouteMapping from "utils/AllApiRouteMapping";
import helperSpaceDocumentationImpl from "data/helperSpaceDocumentation/helperSpaceDocumentationImpl";


export default class functions{
    async previewPagebySpaceId(spaceId){
        const payload = {currentSpaceId : spaceId};
        let result = await axiosCall(ApiRouteMapping.previewContent.apiPath, ApiRouteMapping.previewContent.method, null, null, payload);
        const helperSpaceDocumentationHandler = new helperSpaceDocumentationImpl();
        helperSpaceDocumentationHandler.initFromspaceDocumentation(result.data.pages);
        helperSpaceDocumentationHandler.setcurrentPagetoFirstPage();
        return helperSpaceDocumentationHandler;
    }

    async previewContentbyPageId(dataJson){
        const payload = {pageId : dataJson.currentPage.Id};
        let result = await axiosCall(ApiRouteMapping.previewPageContent.apiPath, ApiRouteMapping.previewPageContent.method, null, null, payload);

        const helperSpaceDocumentationHandler = new helperSpaceDocumentationImpl();
        helperSpaceDocumentationHandler.initDataFromObj(dataJson);
        helperSpaceDocumentationHandler.saveContent(result.data.content);
        return helperSpaceDocumentationHandler;
    }
}