import React, { Component } from 'react'
import PricingCard from 'components/PricingCard/PricingCard'
import functions from './functions';

interface state{
    pricingPlan: {}
}
export default class Pricing extends Component<any, state> {
  constructor(props){
    super(props);

    this.init = this.init.bind(this);
    this.handleSubscription = this.handleSubscription.bind(this);
    
    this.state = {
        pricingPlan : {} 
    }
  }

  componentDidMount(): void {
    this.init();
  }

  async init() {
    const pricingHandler = new functions();
    const priceData = await pricingHandler.getStripeProductsDetails();
    console.log(priceData)
    this.setState({pricingPlan: priceData})
  }

  async handleSubscription(e: any, priceId: string) {
    e.preventDefault();
    
    let data = {
        id: priceId,
        headers: {
            "Content-Type": 'application/json'
        }
    }
    const pricingHandler = new functions();
    const checkout = await pricingHandler.checkoutSubscription(data);
    // console.log(checkout);
    // router.push(checkout);
    window.location.assign(checkout);
  }

  render() {
    return (
      <>
        <PricingCard 
            pricingPlan={this.state.pricingPlan}
            handleSubscription={this.handleSubscription}
        />
      </>
    )
  }
}
