import { default as React } from 'react';
import ReactDOM from 'react-dom';
import FlowChart from './FlowChart';

export default class FlowChartClass {

    api: string;
    readOnly: boolean;
    data: any;
    CSS: any;
    nodes: any;

    constructor({ data, config, api, readOnly }) {
        this.api = api;
        this.readOnly = readOnly;
        this.data = data.flow;

        this.CSS = {
            wrapper: 'walkthrough-timeline',
        };

        this.nodes = {
            holder: null,
        };
        this.save = this.save.bind(this);
        this.onDataChange = this.onDataChange.bind(this);
    }

    static get toolbox() {
        return {
            icon: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M20 23h-8.14a4.17 4.17 0 0 0-.43-1L22 11.43a3.86 3.86 0 0 0 2 .57a4 4 0 1 0-3.86-5h-8.28a4 4 0 1 0 0 2h8.28a4.17 4.17 0 0 0 .43 1L10 20.57A3.86 3.86 0 0 0 8 20a4 4 0 1 0 3.86 5H20v3h8v-8h-8ZM8 10a2 2 0 1 1 2-2a2 2 0 0 1-2 2Zm16-4a2 2 0 1 1-2 2a2 2 0 0 1 2-2ZM8 26a2 2 0 1 1 2-2a2 2 0 0 1-2 2Zm14-4h4v4h-4Z"/></svg>`,
            title: 'Flow Chart',
        };
    }

    static get isReadOnlySupported() {
        return true;
    }

    save() {
        return {
            flow: this.data
        };
    }

    onDataChange(data) {
        this.data= data;
    }

    render() {
        const rootNode = document.createElement('div');
        rootNode.setAttribute('class', this.CSS.wrapper);
        this.nodes.holder = rootNode;


        ReactDOM.render(
            (
                <FlowChart
                    onDataChange={this.onDataChange}
                    readOnly={this.readOnly}
                    saveCallback={this.save} 
                    data = {this.data}
                />
            ),
            rootNode);

        return this.nodes.holder;
    }
}