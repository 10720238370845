import axiosCall from "lib/axios";
import pageSEOImpl from "data/SEO/pageSEO/pageSEOImpl";
import PageSEOData from "utils/AllPageSEOData";
import helperFunctionsImpl from "utils/helperFunctionsUtils/helperFunctionsImpl";



export default class functions{

    addSEO() {
        const pageSEO = new pageSEOImpl();
        const data = PageSEOData.Login;
        pageSEO.initFromDataObject(data);
        pageSEO.seturl(helperFunctionsImpl.getCurrentUrl())
        return pageSEO;
    }
    
    async loginUser(user) {
        let headersJson = {
            headers: {
                'x-auth-token': user.credential
            } 
        }

        let result = await axiosCall('/user/', "POST", null, headersJson, null);
        return result.data;
    } 
}