import globalAttributes from "./globalAttributesJson";

const PageSEOData = {
    LandingPage: {
        title: "Find Code Snippets for Beautiful Web Designs Components | BuildFast",
        description: "Find high-quality, User centric, mobile responsive web components code. Find components used by LinkedIn, Airbnb, Instamart, Swiggy, SpaceX, and many more. Find code in HTML, CSS, Javascript.",
        image: "https://ik.imagekit.io/c8xfvkbcd/Landing_Page_Images/hero-image.png?updatedAt=1687578867987",
        url: globalAttributes.websiteLink,
        pagetype: "website",
        keywords: "HTML/CSS components, ui library, ui animation library, ui"
    },
    Login: {

    },
    Dashboard: {

    },
    DocumentationPage: {

    }
}

export default PageSEOData;