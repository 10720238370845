import React, { Component } from 'react'

import EditorJS from "@editorjs/editorjs";
import Header from '@editorjs/header';
import { ToolConstructable } from '@editorjs/editorjs';

import ImageTool from '@editorjs/image';
import NestedList from '@editorjs/nested-list';
import LinkTool from '@editorjs/link';
import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import Warning from './Warning/Warning';
import RawTool from '@editorjs/raw';
import InlineCode from '@editorjs/inline-code';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';
import spaceDocumentationInterface from 'data/spaceDocumentation/spaceDocumentationInterface';
import Timeline from './Timeline/Timeline';
import Imagekit from 'lib/ImageKit/ImageKit';
import axiosCall from 'lib/axios';
import ApiRouteMapping from 'utils/AllApiRouteMapping';
import Delimiter from '@editorjs/delimiter';
import CodeBlock from './CodeBlock/CodeBlock';
import FlowChartClass from './FlowChart/FlowChartClass';

import './Editor.css'



interface props {
    editorContent: any
    currentPage: spaceDocumentationInterface,
    isReadOnly: boolean
}

interface state {
    ejInstance: any,
    isRunnedForCurrentPage: boolean
}

const DEFAULT_EDITOR_DATA = {
    "time": new Date().getTime(),
    "blocks": [
        {
            "type": "header",
            "data": {
                "text": "This is my awesome editor!",
                "level": 1
            }
        },
    ]

};

let initialData;


export default class Editorjs extends Component<props, state> {
    constructor(props) {
        super(props);

        this.state = {
            ejInstance: React.createRef,
            isRunnedForCurrentPage: false
        }
    }

    initEditor() {
        const editor = new EditorJS({
            holder: 'editorjs',
            onReady: async () => {
                editor.blocks?.render(initialData);
                // this.state.ejInstance.current = editor;
                await this.setState({
                    ejInstance: {
                        ...this.state.ejInstance,
                        current: editor
                    }
                })
            },
            autofocus: true,
            onChange: async () => {
                let content = await editor?.saver?.save();
                this.props.editorContent(JSON.stringify(content));
            },
            tools: {
                header: Header,
                image: {
                    class: ImageTool,
                    config: {
                        uploader: {
                            /**
                            * Upload file to the server and return an uploaded image data
                            * @param {File} file - file selected from the device or pasted by drag-n-drop
                            * @return {Promise.<{success, file: {url}}>}
                            */
                            async uploadByFile(selectedFile) {
                                
                                const imageKitHandler = new Imagekit();
                                imageKitHandler.initializeIKCoreContext();
                                let image = await imageKitHandler.uploadImagethroughIKCoreContext(selectedFile);

                                let returnJson = {
                                    success: 1,
                                    file: {
                                        url: image.url
                                    }
                                }
                                return returnJson
                            },
                        }
                    }
                },
                list: {
                    class: NestedList,
                    inlineToolbar: true,
                    config: {
                        defaultStyle: 'unordered'
                    },
                },
                linkTool: {
                    class: LinkTool,
                    config: {
                        endpoint: ApiRouteMapping.fetchLinkData.url, // Your backend endpoint for url data fetching,
                    }
                },
                embed: Embed,
                table: {
                    class: Table,
                    inlineToolbar: true,
                    config: {
                        rows: 2,
                        cols: 3,
                    },
                },
                warning: {
                    class: Warning as unknown as ToolConstructable,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+W',
                    config: {
                        titlePlaceholder: 'Title',
                        messagePlaceholder: 'Message',
                    },
                },
                flow:{
                    class: FlowChartClass as unknown as ToolConstructable,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+P',
                },
                code: {
                    class: CodeBlock,
                    shortcut: 'CMD+SHIFT+D',
                },
                delimiter: Delimiter,
                raw: RawTool,
                inlineCode: {
                    class: InlineCode,
                    shortcut: 'CMD+SHIFT+M',
                },
                timeline: Timeline,
            },
            readOnly: this.props.isReadOnly
        });
    };

    async componentDidMount() {
        if(!stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage)) {
            const payload = { pageId: this.props.currentPage.Id };
            let data = await axiosCall(ApiRouteMapping.updateEditorContent.apiPath, ApiRouteMapping.updateEditorContent.method, null, null, payload);
            await this.destroyCurrentejInstance();

            if (!stringUtilsImpl.isUndefinedEmptyorNull(data) && !stringUtilsImpl.isUndefinedEmptyorNull(data.data) && !stringUtilsImpl.isUndefinedEmptyorNull(data.data.content)) {
                initialData = JSON.parse(data.data.content);
            } else {
                initialData = DEFAULT_EDITOR_DATA
            }

            if (this.props.isReadOnly) {
                await this.initEditor();
            } else {
                this.initEditor();
            }
        }
    }

    async componentDidUpdate(prevProps, pervState) {

        if (!stringUtilsImpl.isUndefinedEmptyorNull(this.props.currentPage) && 
            (prevProps.currentPage !== this.props.currentPage)) {
            
            const payload = { pageId: this.props.currentPage.Id };
            let data = await axiosCall(ApiRouteMapping.updateEditorContent.apiPath, ApiRouteMapping.updateEditorContent.method, null, null, payload);
            await this.destroyCurrentejInstance();
            
            if(!stringUtilsImpl.isUndefinedEmptyorNull(data) && !stringUtilsImpl.isUndefinedEmptyorNull(data.data) && !stringUtilsImpl.isUndefinedEmptyorNull(data.data.content)) {
                initialData = JSON.parse(data.data.content);  
            } else {
                initialData = DEFAULT_EDITOR_DATA
            }
            
            if(this.props.isReadOnly) {
                await this.initEditor();
            } else {
                this.initEditor();
            }
            
        }
    }
    
    async destroyCurrentejInstance() {
        this.state.ejInstance?.current?.destroy();
        // this.state.ejInstance.current = null;
        await this.setState({
            ejInstance: {
                ...this.state.ejInstance,
                current: null
            }
        })
    }

    async handleIsRunnedChange(value: boolean) {
        await this.setState({
            isRunnedForCurrentPage: value
        })
    }
    

    render() {
        return (
            <div id='editorjs' className='editor-js'></div>
        )
    }
}
