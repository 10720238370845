import spaceDocumentationInterface from "data/spaceDocumentation/spaceDocumentationInterface";
import helperSpaceDocumentationInterface from "./helperSpaceDocumentationInterface";
import stringUtilsImpl from "utils/stringUtils/stringUtilsImpl";
import spaceDocumentationImpl from "data/spaceDocumentation/spaceDocumentationImpl";
import spaceDocumentationENUM from "data/ENUMS/spaceDocumentationENUM";
import spaceInterface from "data/space/spaceInterface";

export default class helperSpaceDocumentationImpl implements helperSpaceDocumentationInterface {
    navItems: spaceDocumentationInterface[][] = [];
    currentSpaceId: string
    currentSpace: spaceInterface
    currentSpaceName: string
    currentPage: spaceDocumentationInterface
    // currentPageId: string
    currentPageContent: string;
    currentPageIdState: spaceDocumentationENUM
    pageIndex: number = 0 // Increase Page Values on every insert
    groupIndex: number = 0 // Increase Group Values on every new group insert

    groupNameSelected: string  // GroupName selected for Rename
    pageIdSelected: string  //PageId selected for Rename

    initDataFromObj(data) {
        if(stringUtilsImpl.isUndefinedEmptyorNull(data)) {
            return;
        }
        
        this.currentSpaceId = data.currentSpaceId;
        this.currentSpace = data.currentSpace;
        this.currentSpaceName = data.currentSpaceName;
        this.currentPage = data.currentPage;
        // this.currentPageId = data.currentPageId;
        this.groupNameSelected = data.groupNameSelected;
        this.pageIdSelected = data.pageIdSelected;
        this.currentPageContent = data.currentPageContent;

        if (!stringUtilsImpl.isUndefinedEmptyorNull(data.navItems)) {
            for (let i = 0; i < data.navItems.length; i++) {
                this.navItems.push(data.navItems[i])
                this.increasePageIndex(data.navItems[i]);
                this.increaseGroupIndex(data.navItems[i]);
            }
        }

        
    }

    initFromspaceDocumentation(dataArray) {
        let json = {};
        // let pageIdJson = {};
        let dataArraywithObj = [];

        if (!stringUtilsImpl.isUndefinedEmptyorNull(dataArray)) {

            for(let i=0; i<dataArray.length; i++) {
                const spaceDocumentationObj = new spaceDocumentationImpl();
                spaceDocumentationObj.initFromDataObj(dataArray[i]);
                dataArraywithObj.push(spaceDocumentationObj);
            }

            for(let i=0; i<dataArraywithObj.length; i++){
                let spaceDocumentationObj = dataArraywithObj[i];

                if(spaceDocumentationObj.getgroupName() === undefined) {
                    this.navItems.push([spaceDocumentationObj]);
                    continue;
                }

                if(spaceDocumentationObj.getgroupName() in json){
                    let arrayIndex = json[spaceDocumentationObj.getgroupName()];
                    this.navItems[arrayIndex].push(spaceDocumentationObj); 
                } else{
                    json[spaceDocumentationObj.getgroupName()] = this.navItems.length;
                    this.navItems.push([spaceDocumentationObj]);
                }
            }

            this.setPrevNextObj();
        }
    }

    setPrevNextObj() {

        let spaceObjectSequence = [];

        for(let i = 0; i < this.navItems.length; i++) {
            for( let j = 0; j < this.navItems[i].length; j++) {
                spaceObjectSequence.push(this.navItems[i][j]);
            }
        }

        for (let i = 0; i < spaceObjectSequence.length; i++) {
            if (i === 0) {
                spaceObjectSequence[i].setnextPage(spaceObjectSequence[i+1]);
            }
            else if (i === spaceObjectSequence.length -1){
                spaceObjectSequence[i].setprevPage(spaceObjectSequence[i-1]);
            }
            else{
                spaceObjectSequence[i].setprevPage(spaceObjectSequence[i-1]);
                spaceObjectSequence[i].setnextPage(spaceObjectSequence[i+1]);
            }
        }

        spaceObjectSequence = [];
    }

    setcurrentPagetoFirstPage() {
        if(this.navItems.length>0) {
            this.currentPage = this.navItems[0][0]
        }
    }

    getcurrentSpaceName() {
        return this.currentSpaceName
    }

    setcurrentSpaceName(currentSpaceName: string) {
        this.currentSpaceName = currentSpaceName
    }

    setcurrentSpaceId(currentSpaceId: string) {
        this.currentSpaceId = currentSpaceId;
    }

    getcurrentSpaceId() {
        return this.currentSpaceId;
    }

    increasePageIndex(data) {
        if(data.length === undefined) {
            this.pageIndex = this.pageIndex + 1;
        } else {
            this.pageIndex = this.pageIndex +  data.length;
        }
    }

    increaseGroupIndex(data) {
        if (data.length > 0) {
            if(data[0].groupName === undefined) {
                return;
            } else {
                this.groupIndex = this.groupIndex + 1;
            }
        }
    }

    addNewGroup(dataGroup) {
        const dataGroupObj = new spaceDocumentationImpl();
        dataGroupObj.initFromDataObj(dataGroup);
        dataGroupObj.setpageName(dataGroupObj.getpageName() + " " + this.pageIndex);
        dataGroupObj.setgroupName(dataGroupObj.getgroupName() + " " + this.groupIndex);
        dataGroupObj.setspaceId(this.currentSpaceId);
        dataGroupObj.setsequenceNumber(this.pageIndex);
        dataGroupObj.setisCreate(spaceDocumentationENUM.CREATE);

        let groupArray = [dataGroupObj];
        if(this.navItems.length === 1 && this.navItems[0].length === 0) {
            this.navItems[0] = groupArray
        } else {
            this.navItems.push(groupArray);
        }
    }

    addNewPage(dataPage){
        const dataPageObj = new spaceDocumentationImpl();
        dataPageObj.initFromDataObj(dataPage);
        dataPageObj.setpageName(dataPageObj.getpageName() + " " + this.pageIndex);
        dataPageObj.setspaceId(this.currentSpaceId);
        dataPageObj.setsequenceNumber(this.pageIndex);
        dataPageObj.setisCreate(spaceDocumentationENUM.CREATE);

        let pageArray = [dataPageObj];
        if(this.navItems.length === 1 && this.navItems[0].length === 0) {
            this.navItems[0] = pageArray;
        } else {
            this.navItems.push(pageArray);
        }
    }

    addNewGroupPage(dataGroupPage){
        const dataGroupPageObj = new spaceDocumentationImpl();
        dataGroupPageObj.initFromDataObj(dataGroupPage);
        dataGroupPageObj.setpageName(dataGroupPageObj.getpageName() + " " + this.pageIndex);
        dataGroupPageObj.setspaceId(this.currentSpaceId);
        dataGroupPageObj.setsequenceNumber(this.pageIndex);
        dataGroupPageObj.setisCreate(spaceDocumentationENUM.CREATE);

        for(let i=0; i<this.navItems.length; i++){
            if(this.navItems[i][0].getgroupName() === dataGroupPage.groupName){
                this.navItems[i].push(dataGroupPageObj);
            }
        }

    }

    renameGroup(newGroupName) {
        if(stringUtilsImpl.isUndefinedEmptyorNull(newGroupName)) {
            return;
        }

        let Index = -1;
        for(let i = 0; i < this.navItems.length; i++) {
            if (this.navItems[i][0].getgroupName() === this.groupNameSelected) {
                Index = i
            }
        }

        for(let i = 0; i < this.navItems[Index].length; i++) {
            if(this.navItems[Index][i].getgroupName() === this.groupNameSelected) {
                this.navItems[Index][i].setgroupName(newGroupName);
                if(this.navItems[Index][i].getisCreate() !== spaceDocumentationENUM.CREATE) {
                    this.navItems[Index][i].setisCreate(spaceDocumentationENUM.UPDATE);
                }
                
            }
        }
    }

    renamePage(newPageName) {
        if(stringUtilsImpl.isUndefinedEmptyorNull(newPageName)) {
            return;
        }

        let Index = -1;
        // && this.navItems[i][0].Id === this.pageIdSelected
        for(let i=0; i < this.navItems.length; i++){
            if (!stringUtilsImpl.isUndefinedEmptyorNull(this.navItems[i][0].getgroupName()) && !stringUtilsImpl.isUndefinedEmptyorNull(this.groupNameSelected) && (this.navItems[i][0].getgroupName() === this.groupNameSelected)) {
                Index = i;
                break;
                
            }
            if(this.navItems[i][0].Id === this.pageIdSelected) {
                this.navItems[i][0].setpageName(newPageName);
                if(this.navItems[i][0].getisCreate() !== spaceDocumentationENUM.CREATE) {
                    this.navItems[i][0].setisCreate(spaceDocumentationENUM.UPDATE);
                }
                return;
            }
        }

        for(let i=0; i < this.navItems[Index].length; i++){
            if(this.navItems[Index][i].Id === this.pageIdSelected){
                this.navItems[Index][i].setpageName(newPageName);
                if(this.navItems[i][0].getisCreate() !== spaceDocumentationENUM.CREATE) {
                    this.navItems[i][0].setisCreate(spaceDocumentationENUM.UPDATE);
                }
            }
        }
    }

    saveContent(content){
        if (stringUtilsImpl.isUndefinedEmptyorNull(content)) {
            return;
        }

        let Index = -1;
        for(let i=0; i < this.navItems?.length; i++){
            if (!stringUtilsImpl.isUndefinedEmptyorNull(this.navItems[i][0].getgroupName()) && !stringUtilsImpl.isUndefinedEmptyorNull(this.groupNameSelected) && (this.navItems[i][0].getgroupName() === this.groupNameSelected)) {
                Index = i;
                break;
            }
            
            if (this.navItems[i][0]?.Id === this.currentPage?.Id) {
                this.navItems[i][0].setContent(content);
                if (this.navItems[i][0].getisCreate() !== spaceDocumentationENUM.CREATE) {
                    this.navItems[i][0].setisCreate(spaceDocumentationENUM.UPDATE);
                }
                return;
            }
        }

        for(let i=0; i < this.navItems[Index]?.length; i++){
            if(this.navItems[Index][i].Id === this.currentPage.Id){
                this.navItems[Index][i].setContent(content);
                this.currentPageContent = content;
                if (this.navItems[i][0].getisCreate() !== spaceDocumentationENUM.CREATE) {
                    this.navItems[i][0].setisCreate(spaceDocumentationENUM.UPDATE);
                }
            }
        }
    }

    convertToArrayOfSpaceDocumentationInterface(){

        let createArray: spaceDocumentationInterface[] = [];
        let updateArray: spaceDocumentationInterface[] = [];
        for(let i=0; i< this.navItems.length; i++){
            for(let j=0; j<this.navItems[i].length; j++){
                let newObj = this.navItems[i][j];
                newObj.setnextPage(undefined);
                newObj.setprevPage(undefined);
                if (this.navItems[i][j].getisCreate() === spaceDocumentationENUM.CREATE) {
                    createArray.push(newObj);
                } else if (this.navItems[i][j].getisCreate() === spaceDocumentationENUM.UPDATE) {
                    updateArray.push(newObj);
                }
                
            }
        }

        const json = {
            'CREATE': createArray,
            'UPDATE': updateArray
        }
        return json;
    }
    
}
