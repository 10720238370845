import React, { Component } from 'react'
import SidebarMenu from '../SidebarMenu/SidebarMenu'
import './LeftSidebar.css'
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl'
import helperSpaceDocumentationInterface from 'data/helperSpaceDocumentation/helperSpaceDocumentationInterface'
import SingleMenu from 'components/SingleMenu/SingleMenu'

interface props{
  sidebarList : helperSpaceDocumentationInterface,
  onPageClick: any
}
export default class LeftSidebar extends Component<props, any> {
  render() {
    return (
      <>
        <section className="leftbar-section" id="left-sidebar">
            <div className="leftbar-container">
              {
                !stringUtilsImpl.isUndefinedEmptyorNull(this.props.sidebarList) &&  !stringUtilsImpl.isUndefinedEmptyorNull(this.props.sidebarList.navItems) ?
                <>
                  {
                    this.props.sidebarList.navItems.map((listItem) => {
                      if(listItem[0].getgroupName() !== undefined){
                        return(
                          <SidebarMenu menuHeading={listItem[0].getgroupName()} pageList = {listItem} addClass = 'is-group-page' onPageClick={this.props.onPageClick} isPageActive={listItem[0].Id === this.props.sidebarList?.currentPage?.Id}/>
                        )
                      }
                      else{
                          return(
                            <SingleMenu menuName = {listItem[0].getpageName()}  addClass='' pageList={listItem} onPageClick={this.props.onPageClick} isPageActive={listItem[0].Id === this.props.sidebarList.currentPage?.Id}/>
                          )
                      }
                    })
                  }
                </>
                :
                <></>
              }

            </div>
        </section>
      </>
    )
  }
}
