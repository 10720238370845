import React, { Component } from 'react'
import SingleMenu from '../SingleMenu/SingleMenu';

import './SidebarMenu.css'
import spaceDocumentationInterface from 'data/spaceDocumentation/spaceDocumentationInterface';
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';

interface props{
  menuHeading: string,
  pageList: spaceDocumentationInterface[],
  addClass: string,
  onPageClick: any,
  isPageActive: boolean
}

export default class SidebarMenu extends Component<props> {
    
  render() {
    return (
      <>
        <div className="menu-heading">
            <div className="menu-name">
                {this.props.menuHeading}
            </div>
            {
              !stringUtilsImpl.isUndefinedEmptyorNull(this.props.pageList) ?
              <>
              {
                this.props.pageList.map((page)=>{
                  return(<SingleMenu menuName={page.getpageName()}  addClass={this.props.addClass} pageList={this.props.pageList} onPageClick={this.props.onPageClick} isPageActive={this.props.isPageActive}/>)
                })
              }
              </>
              :
              <></>
            }
        </div>
      </>
    )
  }
}
