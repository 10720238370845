import pageSEOInterface from "data/SEO/pageSEO/pageSEOInterface";

export default class pageSEOImpl implements pageSEOInterface {
    title: string;
    description: string;
    image: string;
    url: string;
    pagetype: string
    keywords: string;

    public initFromDataObject(data) {
        this.title = data.title;
        this.description = data.description;
        this.image = data.image;
        this.url = data.url;
        this.pagetype = "website"
        this.keywords = data.keywords;
    }

    public settitle(title) {
        this.title = title + " | Buildfast";
    }
    
    public setdescription(description) {
        this.description = description;
    }

    public setimage(image) {
        this.image = image;
    }

    public seturl(url) {
        this.url = url;
    }

    public setkeywords(keywords) {
        this.keywords = keywords;
    }

    public gettitle() {
        return this.title;
    }

    public getdescription() {
        return this.description;
    }

    public getimage(){
        return this.image;
    }

    public geturl() {
        return this.url;
    }

}
