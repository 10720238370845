import React, { Component } from 'react'
import './InputListItem.css'
import spaceInterface from 'data/space/spaceInterface'
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl'

interface props {
  space: spaceInterface
  handleCurrentSpaceId: any
  selectedSpaceId: string
}

export default class InputListItem extends Component<props, {}> {
  render() {
    return (
      <>
        <div className={ (!stringUtilsImpl.isUndefinedEmptyorNull(this.props.selectedSpaceId)) &&  (this.props.selectedSpaceId === this.props.space.Id) ? "ip-list ip-list-active" : "ip-list"} data-spaceId={this.props.space.Id} onClick={(e) => this.props.handleCurrentSpaceId(e, this.props.space.Id, this.props.space.spaceName)}>
            <div className="ip-list-left">
                <p>📙</p>
                <p>{this.props.space.spaceName}</p>
            </div>
            <div className="ip-list-right">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g transform="rotate(90 10 10)"><path fill="#fff" d="M14 10.25a1.25 1.25 0 1 1 2.5 0a1.25 1.25 0 0 1-2.5 0Zm-5 0a1.25 1.25 0 1 1 2.5 0a1.25 1.25 0 0 1-2.5 0Zm-5 0a1.249 1.249 0 1 1 2.5 0a1.25 1.25 0 1 1-2.5 0Z"/></g></svg>
            </div>
        </div>
      </>
    )
  }
}
