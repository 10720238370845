import React, { Component } from 'react'
import './Overlay.css'

interface props{
    label: string
    className: string
    overlayBtn: string
    hideDialogBox: any
    newElementName: any
}

interface renameInterface {
    value: string
}


export default class Overlay extends Component<props, renameInterface> {
    constructor(props){
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitForm = this.submitForm.bind(this);

        this.state = {
            value: '' as string
        }
    }

    handleInputChange(e) {
        this.setState({
            value: e.target.value
        })
    }

    submitForm(e){
        e.preventDefault()
        const sendValue = this.state.value;
        this.setState({
            value : ""
        }, ()=>{
            this.props.newElementName(e, sendValue);
        }) 
    }

  render() {
    return (
      <>
         <div className={"overlay-container " + this.props.className}>
            <div className="overlay-wrapper">
                <div className="overlay-box">
                        <form onSubmit={this.submitForm}>
                        <div>
                            <div className="close-btn">
                                <div className="close-btn-icon" onClick={this.props.hideDialogBox}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 36 36"><path fill="#3b454e" d="m19.41 18l8.29-8.29a1 1 0 0 0-1.41-1.41L18 16.59l-8.29-8.3a1 1 0 0 0-1.42 1.42l8.3 8.29l-8.3 8.29A1 1 0 1 0 9.7 27.7l8.3-8.29l8.29 8.29a1 1 0 0 0 1.41-1.41Z"/><path fill="none" d="M0 0h36v36H0z"/></svg>
                                </div>
                            </div>
                            <p className='space-text'>{this.props.label}</p>
                                <input type="text" name="value" id="" value={this.state.value} className='space-input' onChange={this.handleInputChange} required/>
                        </div>
                        <div>
                            <div className='new-space-btn-box'>
                                <button className="rename-space" type="submit">
                                    <p>{this.props.overlayBtn}</p>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
      </>
    )
  }
}
