import React, { Component } from 'react'
import $ from "jquery";
import './Header.css'

interface props{
  isSearchBarVisible: boolean
  isSignInVisible: boolean,
  isHamburgerVisible: boolean
}

export default class Header extends Component<props> {
  constructor(props){
    super(props);
    this.onclickHamburger = this.onclickHamburger.bind(this);
  }

  onclickHamburger(){
    $("#left-sidebar").toggleClass('leftbar-active');
  }

  render() {
    return (
      <>
        <section className="header-section">
            <div className="header-container">
                <div className="header-left">
                    <div className={this.props.isHamburgerVisible ? "header-hamburger" : "header-hamburger hide-hamburger"} onClick={this.onclickHamburger}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="#3b454e" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 17h14M5 12h14M5 7h14"/></svg>
                    </div>
                    <p>DevBook</p>
                </div>
                <div className="header-right">
                  <div className={this.props.isSignInVisible ? "header-signin" : "header-signin-hide"}>
                    <a className='header-signin-link' href="sagar">Sign In</a>
                  </div>
                  <div className={this.props.isSearchBarVisible ? "header-search" : "header-search-hide"}>
                    <input type="search" name="" id="" placeholder='Search' />
                    <div className="header-search-sc">
                        <p>⌘K</p>
                    </div>
                  </div>
                </div>
            </div>
        </section>
      </>
    )
  }
}
