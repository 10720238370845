import { v4 as uuidv4 } from 'uuid';

import stringUtilsImpl from "utils/stringUtils/stringUtilsImpl";
import spaceDocumentationInterface from "./spaceDocumentationInterface";
import spaceDocumentationENUM from "data/ENUMS/spaceDocumentationENUM";

export default class spaceDocumentationImpl implements spaceDocumentationInterface {
    Id: string; // current page id
    pageName: string;
    content: string;
    sequenceNumber: Number;
    spaceId: string;
    groupName: string;
    moreInfo: string;
    prevPage: spaceDocumentationInterface; // previous page: spaceDocumentationInterface. prevPage.getId()
    nextPage: spaceDocumentationInterface;
    createdAt: string;
    updatedAt: string;
    isCreate: spaceDocumentationENUM;

    initFromDataObj(data, isCreated=null) {

        if(!stringUtilsImpl.isUndefinedEmptyorNull(data.Id)) {
            this.Id = data.Id;
        } else {
            this.Id = uuidv4();
        }
        
        this.pageName = data.pageName;
        this.content = data.content;
        this.sequenceNumber = data.sequenceNumber;
        this.spaceId = data.spaceId;
        this.groupName = data.groupName;
        this.moreInfo = data.moreInfo;
        this.prevPage = data.prevPage;
        this.nextPage = data.nextPage;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;

        if(stringUtilsImpl.isUndefinedEmptyorNull(isCreated)) {
            this.isCreate = spaceDocumentationENUM.NOCHANGE;
        }
    }

    getId() {
        return this.Id;
    }

    setpageName(pageName) {
        this.pageName = pageName;
    }

    getpageName() {
        return this.pageName
    }

    getgroupName() {
        return this.groupName;
    }

    setgroupName(groupName) {
        this.groupName = groupName;
    }

    setspaceId(spaceId) {
        this.spaceId = spaceId;
    }

    getContent() {
        return this.content;
    }

    setContent(content: string) {
        this.content = content;
    }

    setsequenceNumber(sequenceNumber: number) {
        this.sequenceNumber = sequenceNumber;
    }

    setisCreate(isCreate: spaceDocumentationENUM) {
        this.isCreate = isCreate;
    }

    getisCreate() {
        return this.isCreate;
    }

    setprevPage(previousPage){
        this.prevPage = previousPage;
    }
    setnextPage(nextPage){
        this.nextPage = nextPage;
    }
    getprevPage(){
        return this.prevPage;
    }

    getnextPage(){
        return this.nextPage;
    }
}