import React, { Component } from 'react'
import './SidebarLink.css'

interface props{
   onPageName: string,
   onPageLink: string,
   onClick: any,
}

export default class SidebarLink extends Component<props> {
    
  render() {
    return (
      <>
        <div className="sbl-container " onClick={this.props.onClick}>
            <a href={this.props.onPageLink}>{this.props.onPageName}</a>
        </div>
      </>
    )
  }
}
