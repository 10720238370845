import axios from "axios";

import env from 'config'
import jsonUtilsImpl from "utils/jsonUtils/jsonUtils";

const instance = axios.create({
    baseURL: env.app.url
});

async function axiosCall(URL, method, params=null, headers = null, payload=null) {
    let response;
    let configJson = {}
    let headersJson = {};

    if (params !== null && !jsonUtilsImpl.isEmpty(params)) {
        configJson['params'] = params;
    }

    if (localStorage.getItem(env.app.appName +'_token')) {
        headersJson = {
            'x-auth-token': localStorage.getItem(env.app.appName + '_token')
        }
    }

    if (headers !== null && !jsonUtilsImpl.isEmpty(headers)) {
        headersJson = headers['headers']
    }

    configJson['headers'] = headersJson
    
    if(method === 'GET') {
        response = await instance.get(URL, configJson);
    }

    if(method === 'POST') {
        response = await instance.post(URL, payload, configJson);
    }

    return response;
}  


export default axiosCall;
