import userFormInterface from "data/user/userFormInterface";
import spaceInterface from "./spaceInterface";
import spacePermissionInterface from "data/spacePermission/spacePermissionInterface";
import stringUtilsImpl from "utils/stringUtils/stringUtilsImpl";
import userFormImpl from "data/user/userFormImpl";
import spacePermissionImpl from "data/spacePermission/spacePermissionImpl";

export default class spaceImpl implements spaceInterface{
    Id: string;
    spaceName: string;
    organizationId: string;
    isPublic: boolean;
    createdAt: string;
    updatedAt: string;
    isShared: boolean;

    adminUser: userFormInterface;
    manageUsers: spacePermissionInterface[] = [];

    initfromDataObj(data) {
        this.Id = data.Id;
        this.spaceName = data.spaceName;
        this.organizationId = data.organizationId;
        this.isPublic = data.isPublic;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
        this.isShared = data.isShared;
    }

    setadminUser(adminUser) {
        if(!stringUtilsImpl.isUndefinedEmptyorNull(adminUser)) {
            const userFormHandler = new userFormImpl();
            userFormHandler.initFromDataObj(adminUser);
            this.adminUser = userFormHandler
        }
    }

    setmanageUser(manageUserList) {
        if(!stringUtilsImpl.isUndefinedEmptyorNull(manageUserList)) {
            for(let i=0; i<manageUserList.length; i++){
                const spacePermissionHandler = new spacePermissionImpl();
                spacePermissionHandler.initfromDataObj(manageUserList[i]);
                this.manageUsers.push(spacePermissionHandler);
            }
        }
    }
}