//config.js
const env = process.env.REACT_APP_REACT_ENV;
// console.log(env);
const dev = {
    app: {
        url: process.env.REACT_APP_DEV_API_URL
    },
    googleLogin: {
        clientid: process.env.REACT_APP_DEV_GOOGLE_CLIENT_ID
    },
    imageKit: {
        publicKey: process.env.REACT_APP_DEV_IMAGEKIT_PUBLIC_KEY,
        privateKey: process.env.REACT_APP_DEV_IMAGEKIT_PRIVATE_KEY,
        urlEndpoint: process.env.REACT_APP_DEV_IMAGEKIT_URL_ENDPOINT,
        authenticationEndpoint: process.env.REACT_APP_DEV_AUTHENTICATION_ENDPOINT
    }
};

const prod = {
    app: {
        url: process.env.REACT_APP_PROD_API_URL
    },
    googleLogin: {
        clientid: process.env.REACT_APP_PROD_GOOGLE_CLIENT_ID
    },
    tinyMCE: {
        apiKey: process.env.REACT_APP_TINYMCE
    },
    imageKit: {
        publicKey: process.env.REACT_APP_PROD_IMAGEKIT_PUBLIC_KEY,
        privateKey: process.env.REACT_APP_PROD_IMAGEKIT_PRIVATE_KEY,
        urlEndpoint: process.env.REACT_APP_PROD_IMAGEKIT_URL_ENDPOINT,
        authenticationEndpoint: process.env.REACT_APP_PROD_AUTHENTICATION_ENDPOINT
    }
};
const config = {
    dev,
    prod
};
module.exports = config[env];
