import React, { Component } from 'react'
import $ from "jquery";
import SidebarLink from '../SidebarLink/SidebarLink'
import './RightSidebar.css'
import stringUtilsImpl from 'utils/stringUtils/stringUtilsImpl';

interface state{
    tableContent : {},
    isTableContentUpdated: boolean
}

export default class RightSidebar extends Component<any, state> {
    constructor(props){
        super(props);

        this.onClick = this.onClick.bind(this);
        this.tableOfContent = this.tableOfContent.bind(this);

        this.state = {
          tableContent : {},
          isTableContentUpdated: false
        }
    }

    async componentDidUpdate(prevProps, prevState) {
      if(!stringUtilsImpl.isUndefinedEmptyorNull(this.props.spaceDoc?.currentPage) && 
        prevProps?.spaceDoc?.currentPage !== this.props.spaceDoc?.currentPage) {
          
        if (!stringUtilsImpl.isUndefinedEmptyorNull(this.props.spaceDoc?.currentPage?.content))  {
          this.tableOfContent(JSON.parse(this.props.spaceDoc?.currentPage?.content))
        }   
      }
    }
    

    handleisTableContentUpdatedHandler(value) {
      this.setState({
        isTableContentUpdated: value
      })
    }

    onClick(e){
        $(".active").removeClass('active');
        $(e.currentTarget).addClass('active');
    }

    tableOfContent(contentArray){

      let mapObj = {};

      for (let blockIndex = 0; blockIndex < contentArray["blocks"].length; blockIndex++) {
        if (contentArray["blocks"][blockIndex].type === "header") {
          mapObj[contentArray["blocks"][blockIndex].id] = contentArray["blocks"][blockIndex].data.text
        }
      }

      this.setState({ tableContent: mapObj })
    }

  render() {
    return (
      <>
        <section className="rsb-section">
            <div className="rsb-container">
                <p className='rsb-heading'>ON THIS PAGE</p>
                {
                  !stringUtilsImpl.isUndefinedEmptyorNull(this.state.tableContent) ?

                   Object.keys(this.state.tableContent).map((page)=>{
                      return(
                        <SidebarLink onPageName={this.state.tableContent[page]} onClick={this.onClick} onPageLink={'#'+page}/>
                      )
                   })
                  :

                  <></>
                }
            </div>
        </section>
      </>
    )
  }
}
