import React, { Component } from 'react'

interface props {
  onDataChange : any
  readOnly: boolean
  data: any
}

export default class eventTimeline extends Component<props, {}> {
  render() {
    return (
      <div>eventTimeline</div>
    )
  }
}
