import React, { Component } from 'react'
import './SingleMenu.css'
import spaceDocumentationInterface from 'data/spaceDocumentation/spaceDocumentationInterface'

interface props{
  menuName: string,
  addClass: string,
  pageList: spaceDocumentationInterface[],
  onPageClick: any
  isPageActive: boolean
}

export default class SingleMenu extends Component<props>{
    
  render() {
    return (
      <>
        <div className={ this.props.isPageActive ? "single-menu single-menu-open " + this.props.addClass : "single-menu " + this.props.addClass} onClick={(e)=>{this.props.onPageClick(e, this.props.pageList[0])}}>
            <div >{this.props.menuName}</div>
        </div>
      </>
    )
  }
}
