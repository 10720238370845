import stringUtilsImpl from "utils/stringUtils/stringUtilsImpl";

export default class jsonUtilsImpl {

    public static isEmpty(data) {
        if (Object.keys(data).length === 0) {
            return true;
        }
        return false;
    }

    static toString(json) {
        return JSON.stringify(json);
    }

    static convertArrayofObjecttoJson(data) {
        if(stringUtilsImpl.isUndefinedEmptyorNull(data)) return undefined;

        let jsonArray = [];
        for(let values in data) {
            jsonArray.push(data[values].toJson());
        }
        return jsonArray;
    }
}